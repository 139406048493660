import axios from 'axios';
import axiosConfig from './config'; // axios请求配置
import apiArr from './api'; // 用户请求集合
import qs from 'qs';
import { Message } from 'element-ui';
import store from '@/store/index.js'
import router from '@/router';

// 创建axios实例
const service = axios.create({
    baseURL: axiosConfig.baseURL,
    timeout: axiosConfig.timeout,
    headers: axiosConfig.headers
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    //  promise动态添加请求头，加完之后再return出config继续请求
    const headerHandlers = (axiosConfig.headerHandlers || []).map(handler => {
        return handler(config).then((mixHeaders) => Object.assign(config.headers || {}, mixHeaders))
    });

    return Promise.all(headerHandlers).then(() => config);
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let data = response.data;
    if (data.code == 200) {
        return data;
    } else {
        if (data.msg.substring(0, 5) == 'token') {
            //需要重新登录
            router.push({ name: 'login' });
            Message.error('请重新登录');
            return data;
        } else {
            Message.error(data.msg);
            return data;
        }
    }

    // else if (data.code == 501) {
    //     Message.error(data.msg);
    //     return data;
    // } else if (data.code == 500) {
    //     Message.error(data.msg);
    //     return data;
    // }
}, function (error) {
    // 对响应错误做点什么
    // Message.error("系统异常，请联系管理人员");
    // console.log(error.message);
    Message.error(error.message);
    return Promise.reject(error);
});

// 创建请求
const USE_DATA_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];
const createRequest = () => {
    const hostApi = {};
    apiArr.forEach(api => {
        hostApi[api.name] = (params) => {
            const method = api.method || "POST";
            //利用qs完成对数组、对象的字符串化
            const requestParams = USE_DATA_METHODS.includes(method) ? { data: qs.stringify({ ...params, frontClientUsing: '仅供前端使用，无需在乎此变量' }, { arrayFormat: 'comma' }) } : { params: { ...params, frontClientUsing: '仅供前端使用，无需在乎此变量' } };
            return service({
                url: api.path,
                method,
                headers: {
                    ...api.headers,
                    saToken: store.state.user.saToken
                },
                ...requestParams,
            })
        }
    });
    return hostApi;
}

const apis = createRequest();

export default apis;
