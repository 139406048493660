<template>
<div>

    <el-dialog title="封禁用户" :before-close="clearInput" :visible.sync="dialogVisibleMul" width="30%">

        <div>
            <el-form ref="form" label-width="80px" size="mini">
                <el-form-item label="封禁用户">
                    <UserHeader v-for="item in multipleSelection" :userId="item.userId" style="margin-right:10px"></UserHeader>
                </el-form-item>
                <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="封禁原因">
                    <el-input v-model="banReason"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmBanMul">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="编辑权限" :before-close="clearInput" :visible.sync="dialogVisibleEdit" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                        <UserHeader :userId="currentBanUserId"></UserHeader>
                    </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item> -->
                <el-form-item label="权限地址">
                    <el-input v-model="currentAuthUrl"></el-input>
                </el-form-item>
                <el-form-item label="权限名">
                    <el-input placeholder="请输入权限名" v-model="editAuthName"></el-input>
                </el-form-item>
                <!-- <el-form-item label="角色中文名">
                    <el-input placeholder="请输入角色中文名" v-model="editRoleChineseName"></el-input>
                </el-form-item> -->
                <el-form-item label="权限描述">
                    <el-input placeholder="请输入权限描述" v-model="editAuthDes"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmEdit">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="新增权限" :before-close="clearInput" :visible.sync="dialogVisible" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                        <UserHeader :userId="currentBanUserId"></UserHeader>
                    </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item> -->
                <el-form-item label="权限名">
                    <el-input placeholder="请输入权限名" v-model="addAuthName"></el-input>
                </el-form-item>
                <el-form-item label="权限地址">
                    <el-input placeholder="请输入权限地址" v-model="addAuthUrl"></el-input>
                </el-form-item>
                <el-form-item label="权限备注">
                    <el-input placeholder="请输入权限备注" v-model="addAuthComment"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="addAuth">确 定</el-button>
        </span>
    </el-dialog>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>

        <el-table-column label="权限编号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.authId }}</template>
        </el-table-column>
        <el-table-column label="权限名" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.authName }}</template>
        </el-table-column>
        <el-table-column label="权限地址" width="200" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.authUrl }}</template>
        </el-table-column>
        <el-table-column label="权限备注" width="180" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.authComment }}</template>
        </el-table-column>
        <!-- <el-table-column label="创建时间时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column> -->
        <el-table-column label="最近编辑时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateTime }}</template>
        </el-table-column>
        <el-table-column align="right" min-width="200">
            <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="请输入权限编号、权限名、权限地址、权限备注" />
            </template>
            <!-- <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    </template> -->
        </el-table-column>
        <el-table-column align="right" :width="currentAuthUserId==-1?420:665">
            <template slot="header" slot-scope="scope">
                <UserHeader v-if="currentAuthUserId!=-1" :userId="currentAuthUserId" style="margin: 0px 10px 0px 10px;"></UserHeader>
                <el-select :remoteMethod="remoteMethod" filterable remote v-model="currentAuthUserId" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择角色">
                    <el-option label="未选用户" :value="-1"></el-option>
                    <el-option v-for="item in queryUsers" :label="item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''" :value="item.userId">
                        <UserHeader headerSize="small" v-if="item.userId!=-1" :userId="item.userId"></UserHeader>
                        <div style="display:inline-block;margin-left:5px;"> {{ ' 昵称：'+item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''}}</div>
                    </el-option>
                </el-select>
                <!-- <el-button size="mini" type="primary" @click="dialogVisible=true">新增角色</el-button> -->

                <el-button size="mini" type="primary" @click="dialogVisible=true">新增权限</el-button>
                <el-button size="mini" type="danger" @click="deleteAuths">批量删除权限</el-button>

                <el-button size="mini" type="success" v-if="currentAuthUserId!=-1" @click="authAll">批量授权</el-button>
                <el-button size="mini" type="danger" v-if="currentAuthUserId!=-1" @click="unAuthAll">批量取消</el-button>

            </template>
            <template slot-scope="scope">
                <el-button size="mini" @click="edit(scope.row.authId)">编辑</el-button>
                <el-button size="mini" type="danger" @click="deleteAuth(scope.row.authId)">删除</el-button>

                <el-button size="mini" v-if="scope.row.currentRoleIsAuth" type="danger" @click="unAuthOne(scope.row.authId)">取消授权</el-button>
                <el-button size="mini" v-else-if="currentAuthUserId!=-1" type="success" @click="authOne(scope.row.authId)">授权</el-button>

            </template>
        </el-table-column>
    </el-table>
    <!-- <div style="margin-top: 20px">
                <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
                <el-button @click="toggleSelection()">取消选择</el-button>
            </div> -->

    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5,10,15,20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
        </el-pagination>

    </div>

</div>
</template>

<script>
import UserHeader from '@/components/business/user/UserHeader.vue';
import dateUtils from '@/utils/dateUtils';
export default {
    name: 'AuthManage',
    components: {
        UserHeader
    },
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            search: '',
            currentPage: 1,
            pageSize: 10,
            dataTotal: 0,
            search: '',
            date: '',
            time: '',
            banReason: '',
            dialogVisibleEdit: false,
            dialogVisible: false,
            dialogVisibleMul: false,
            currentBanUserId: -1,
            addAuthName: '',
            addAuthUrl: '',
            addAuthComment: '',
            editRoleName: '',
            editRoleChineseName: '',
            editRoleDes: '',
            currentEditId: -1,
            currentAuthUserId: -1,
            allRoles: [],
            editAuthName: '',
            editAuthDes: '',
            currentAuthUrl: '',
            queryUsers: []
        };
    },
    watch: {
        currentAuthUserId() {
            this.updatePage();
        },
        search() {
            this.updatePage();
        },
        pageSize() {
            this.updatePage();
        },
        currentPage() {
            this.updatePage();
        }
    },
    mounted() {
        this.updatePage();

    },
    created() {
        this.remoteMethod("");
    },
    methods: {
        deleteAuths() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将批量删除权限 , 是否继续?', '删除权限提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteAuths({
                        authIds: that.multipleSelection.map(item => item.authId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            that.$message({
                                type: 'success',
                                message: '权限删除成功'
                            })
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择需要删除的权限'
                })
            }
        },
        deleteAuth(val) {
            let that = this;
            this.$confirm('此操作将删除权限 , 是否继续?', '删除权限提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteAuths({
                    authIds: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        that.$message({
                            type: 'success',
                            message: '权限删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        remoteMethod(query) {
            let that = this;
            // console.log(query);
            this.$apis.queryUserVos({
                username: query,
                pageNum: 1,
                pageSize: 10
            }).then(res => {
                // console.log(res.rows);
                that.queryUsers = res.rows;
            })
        },
        authOne(val) {
            let that = this;
            this.$confirm('此操作将授权 , 是否继续?', '授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: [val],
                    authOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授权'
                });
            });
        },
        authAll() {
            let that = this;
            this.$confirm('此操作将授权 , 是否继续?', '授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: that.multipleSelection.map(item => item.authId),
                    authOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授权'
                });
            });
        },
        unAuthAll() {
            let that = this;
            this.$confirm('此操作将取消授权  是否继续?', '取消授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: that.multipleSelection.map(item => item.authId),
                    authOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授权'
                });
            });
        },
        unAuthOne(val) {
            let that = this;
            this.$confirm('此操作将取消授权 , 是否继续?', '取消授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: [val],
                    authOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授权'
                });
            });
        },
        confirmEdit() {
            let that = this;
            if (that.currentAuthUrl.length >= 2 && that.currentAuthUrl.length < 200) {
                if (that.editAuthName.length >= 2 && that.editAuthName.length < 200) {
                    if (that.editAuthDes.length >= 2 && that.editAuthDes.length < 200) {

                        that.$apis.editAuth({
                            authId: that.currentEditId,
                            authUrl: that.currentAuthUrl,
                            authName: that.editAuthName,
                            authComment: that.editAuthDes
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.updatePage();
                                that.$message({
                                    type: 'success',
                                    message: '编辑权限成功'
                                })
                                that.clearInput();

                            }
                        })

                    } else {
                        that.$message({
                            type: 'error',
                            message: '权限描述要在2-200字符之间'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '权限名要在2-200字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '权限地址要在2-200字符之间'
                })
            }
        },
        edit(val) {
            this.currentEditId = val;
            let that = this;
            this.$apis.queryAuthById({
                authId: val
            }).then(res => {
                if (res.msg == '操作成功') {
                    that.editAuthName = res.data.authName ? res.data.authName : '';
                    that.editAuthDes = res.data.authComment ? res.data.authComment : '';
                    that.dialogVisibleEdit = true;
                    that.currentAuthUrl = res.data.authUrl ? res.data.authUrl : '';
                }
            })
        },
        deleteRoleMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteRoles({
                        roleIds: that.multipleSelection.map(item => item.roleId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                            that.multipleSelection = [];
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择角色'
                })
            }
        },
        deleteRole(val) {
            let that = this;
            this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteRoles({
                    roleIds: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        addAuth() {
            let that = this;
            if (that.addAuthName.length >= 2 && that.addAuthName.length < 200) {
                if (that.addAuthUrl.length >= 2 && that.addAuthUrl.length < 200) {
                    if (that.addAuthComment.length >= 2 && that.addAuthComment.length < 200) {
                        that.$apis.addAuth({
                            authName: that.addAuthName,
                            authUrl: that.addAuthUrl,
                            authComment: that.addAuthComment
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.updatePage();
                                that.$message({
                                    type: 'success',
                                    message: '添加角色成功'
                                })
                                that.clearInput();

                            }
                        })
                    } else {
                        that.$message({
                            type: 'error',
                            message: '权限备注要在2-200字符之间'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '权限地址要在2-200字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '权限名要在2-200字符之间'
                })
            }

        },

        banMul() {
            if (this.multipleSelection.length > 0) {
                this.dialogVisibleMul = true;
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择用户'
                })
            }
        },

        clearInput() {
            this.addAuthName = '';
            this.addAuthUrl = '';
            this.addAuthComment = '';
            this.editRoleName = '';
            this.editRoleChineseName = '';
            this.editRoleDes = '';
            this.dialogVisible = false;
            this.dialogVisibleMul = false;
            this.dialogVisibleEdit = false;
            this.editAuthDes = '';
            this.editAuthName = '';
            this.currentEditId = -1;
            this.currentAuthUrl = '';
            // done();
        },

        confirmBanMul() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        for (let i = 0; i < that.multipleSelection.length; i++) {
                            that.$apis.banUser({
                                availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                                userId: that.multipleSelection[i].userId,
                                denyReason: that.banReason
                            }).then(res => {
                                if (res.msg = "操作成功") {
                                    that.$message({
                                        type: 'success',
                                        message: '禁用用户(' + that.multipleSelection[i].nickname + ')成功'
                                    })
                                    if (i == that.multipleSelection.length - 1) {
                                        that.updatePage();
                                        that.clearInput();
                                        that.dialogVisibleMul = false;
                                    }
                                }
                            })
                        }

                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        confirmBan() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        that.$apis.banUser({
                            availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                            userId: that.currentBanUserId,
                            denyReason: that.banReason
                        }).then(res => {
                            if (res.msg = "操作成功") {
                                that.updatePage();
                                that.clearInput();
                                that.$message({
                                    type: 'success',
                                    message: '禁用用户成功'
                                })
                                that.dialogVisible = false;

                            }
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        ban(val) {
            this.currentBanUserId = val;
            this.dialogVisible = true;
        },
        updatePage() {
            let that = this;
            this.$apis.queryAuthVos({
                userId: that.currentAuthUserId,
                authName: this.search,
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                orderByColumn: 'update_time',
                isAsc: 'desc'
            }).then(res => {
                // console.log(res);
                that.tableData = res.rows;
                that.dataTotal = res.total;
            })
            // this.$apis.queryRoleVosNoPage({}).then(res => {
            //     if (res.msg == '操作成功') {
            //         that.allRoles = res.data;
            //     }
            // })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
        }
    },
};
</script>

<style scoped>
</style>
