export default [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
        name: "editManageUserPassword",
        path: '/api/user/editManageUserPassword',
        desc: "编辑管理端用户密码",
    },
    {
        name: "editManageUser",
        path: '/api/user/editManageUser',
        desc: "编辑管理端用户",
    },
    {
        name: "querySelfAllRoles",
        path: '/api/user/querySelfAllRoles',
        desc: "查看自身全部角色",
    },
    {
        name: "logout",
        path: '/api/user/logout',
        desc: "退出登录",
    },
    {
        name: "checkIsLogin",
        path: '/api/user/checkIsLogin',
        desc: "检测是否登录",
    },
    {
        name: "kickoutUser",
        path: '/api/user/kickoutUser',
        desc: "踢出用户",
    },
    {
        name: "userGetRoleOrNot",
        path: '/api/user/userGetRoleOrNot',
        desc: "用户获取角色或者取消",
    },
    {
        name: "queryUserGetRole",
        path: '/api/user/queryUserGetRole',
        desc: "查询用户是否获取了角色，并返回全部角色列表",
    },
    {
        name: "banUser",
        path: '/api/user/banUser',
        desc: "封禁用户（也可以用来解封）",
    },
    {
        name: "searchUserVoByUserId",
        path: '/api/user/searchUserVoByUserId',
        desc: "查询用户通过用户Id",
    },
    {
        name: "queryUserVos",
        path: '/api/user/queryUserVos',
        desc: "查询全部用户",
    },
    {
        name: "manageLogin",
        path: '/api/user/manageLogin',
        desc: "账号密码登录（要求是管理者等）",
    }
]