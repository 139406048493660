export default [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
        name: "querySendMinPrice",
        path: '/api/systemConfiguration/querySendMinPrice',
        desc: "查询最低起送价",
    },
    {
        name: "saveSendMinPrice",
        path: '/api/systemConfiguration/saveSendMinPrice',
        desc: "保存最低起送价",
    }
]