export default [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
        name: "userChangeAuth",
        path: '/api/auth/userChangeAuth',
        desc: "用户授权或角色取消授权",
    },
    // {
    //     name: "queryRoleVosNoPage",
    //     path: '/api/role/queryRoleVosNoPage',
    //     desc: "查询全部角色(没有分页)",
    // },
    {
        name: "searchRoleVoById",
        path: '/api/role/searchRoleVoById',
        desc: "搜索角色通过角色编号",
    },
    {
        name: "editRoleById",
        path: '/api/role/editRoleById',
        desc: "编辑角色通过角色编号",
    },
    {
        name: "deleteRoles",
        path: '/api/role/deleteRoles',
        desc: "删除角色",
    },
    {
        name: "addRole",
        path: '/api/role/addRole',
        desc: "新增角色",
    },
    {
        name: "queryRoleVos",
        path: '/api/role/queryRoleVos',
        desc: "查询全部角色(通过角色中文名称)",
    }
]