<template>
<div>
    <el-dialog title="编辑商品" :before-close="beforeClose" :visible.sync="dialogVisible" width="60%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                            <UserHeader :userId="currentBanUserId"></UserHeader>
                        </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                            <el-col :span="11">
                                <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                            </el-col>
                        </el-form-item> -->
                <el-form-item label="商品名称">
                    <el-input placeholder="请输入商品名称" v-model="edit.goodsName"></el-input>
                </el-form-item>
                <el-form-item label="商品类别">
                    <el-select select-when-unmatched :remoteMethod="remoteQueryGoodsCategoryMethod" filterable remote v-model="edit.goodsCategoryId" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择类别">
                        <el-option label="未选择商品类别" :value="-1"></el-option>
                        <el-option v-for="item in edit.frontClientUsing.goodsCategoryList" :label="item.goodsCategoryName" :value="item.goodsCategoryId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品成本">
                    <el-input placeholder="请输入商品成本（卖出价不会低于此价格）" v-model="edit.goodsLowPrice"></el-input>
                </el-form-item>
                <el-form-item label="商品基本价格">
                    <el-input placeholder="请输入商品基本价格（未给特定用户设定价格时，默认用户看到的价格）" v-model="edit.goodsBasePrice"></el-input>
                </el-form-item>
                <el-form-item label="商品最大库存">
                    <el-input placeholder="请输入商品最大库存（用于真实记录库存数）" v-model="edit.goodsMaxRepertory"></el-input>
                </el-form-item>
                <el-form-item label="商品放出库存">
                    <el-input placeholder="请输入商品放出库存（若需要囤货不完全放出全部产品数，可以设置低于最大库存数）" v-model="edit.goodsAllowRepertory"></el-input>
                </el-form-item>
                <el-form-item label="商品单位">
                    <el-input placeholder="请输入商品单位（斤、瓶、箱等等）" v-model="edit.goodsUnit"></el-input>
                </el-form-item>
                <el-form-item label="商品规格">
                    <el-input placeholder="请输入商品规格（500ml*6、25kg*1）" v-model="edit.goodsSpecification"></el-input>
                </el-form-item>
                <el-form-item label="商品描述">
                    <el-input placeholder="请输入商品描述" v-model="edit.goodsDescribe"></el-input>
                </el-form-item>
                <el-form-item label="商品图片">
                    <QuickUploadImage @getUrl="(url)=>edit.goodsImage=url"></QuickUploadImage>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="beforeClose">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import QuickUploadImage from '@/components/image/QuickUploadImage.vue';
export default {
    name: 'EditGoods',
    components: {
        QuickUploadImage
    },
    props: {
        id: {

            default: -1
        }
    },
    watch: {
        id: {
            immediate: true,
            handler(newName, oldName) {
                if (newName >= 1) {
                    this.updatePage();
                }
            }
        },

    },
    data() {
        return {
            dialogVisible: false,
            edit: {
                goodsName: '',
                goodsCategoryId: -1,
                goodsLowPrice: '',
                goodsBasePrice: '',
                goodsMaxRepertory: '',
                goodsAllowRepertory: '',
                goodsUnit: '',
                goodsSpecification: '',
                goodsDescribe: '',
                goodsImage: '',
                frontClientUsing: {
                    imageUrl: '',
                    fileList: [],
                    goodsCategoryList: []
                }
            }
        };
    },
    mounted() {

    },
    methods: {
        //确定操作
        confirm() {
            let that = this;
            that.$apis.editGoods({
                ...that.edit
            }).then(res => {
                if (res.msg == '操作成功') {
                    this.$message({
                        type: 'success',
                        message: '商品修改成功'
                    })
                    this.updatePage();
                    this.beforeClose();

                }

            })
        },
        //更新页面
        updatePage() {
            // console.log("开始更新");
            let that = this;
            that.$apis.searchGoodsById({
                id: that.id
            }).then(res => {
                let data = res.data;
                that.edit = {
                    ...that.edit,
                    ...data
                }

            })

            //初始化刷新远程搜索
            this.remoteQueryGoodsCategoryMethod("");
            //若外部需要同步更新，即可写updatePage事件
            this.$emit('updatePage');
        },
        //关闭对话框之前的动作
        beforeClose() {

            this.imageUrl = '';

            this.dialogVisible = false;
        },
        //打开对话框
        open() {
            this.dialogVisible = true;
        },
        remoteQueryGoodsCategoryMethod(query) {
            let that = this;
            // console.log(query);
            this.$apis.searchGoodsCategory({
                keyword: query,
                pageNum: 1,
                pageSize: 20,
                orderByColumn: 'update_time',
                isAsc: "desc"
            }).then(res => {
                // console.log(res.rows);
                that.edit.frontClientUsing.goodsCategoryList = res.rows;
            })
        },
    },
};
</script>

<style scoped>
</style>
