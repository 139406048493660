let dateUtils = {
    getDate(date) {
        if (date == undefined || date == "") {
            return date;
        }
        let y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = date.getDate()
        d = d < 10 ? '0' + d : d
        return y + '-' + m + '-' + d;
    },
    getTime(date) {
        if (date == undefined || date == "") {
            return date;
        }
        let h = date.getHours()
        h = h < 10 ? '0' + h : h
        let minute = date.getMinutes()
        minute = minute < 10 ? '0' + minute : minute
        let second = date.getSeconds()
        second = second < 10 ? '0' + second : second
        return h + ':' + minute + ':' + second;
    },
    getDateTime(date) {
        if (date == undefined || date == "") {
            return date;
        }
        let y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = date.getDate()
        d = d < 10 ? '0' + d : d
        let h = date.getHours()
        h = h < 10 ? '0' + h : h
        let minute = date.getMinutes()
        minute = minute < 10 ? '0' + minute : minute
        let second = date.getSeconds()
        second = second < 10 ? '0' + second : second
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
    },
    getStandardDate(date) {
        return new Date(date);
    }
}
export default dateUtils;