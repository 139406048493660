<template>
<div>
    <el-menu class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" @click="dialogVisible=true">新增商品</el-menu-item>
        <el-menu-item index="2" @click="deleteMul"">批量删除商品</el-menu-item>
        <el-menu-item index=" 3" @click="upMul">批量上架商品</el-menu-item>
        <el-menu-item index="4" @click="downMul">批量下架商品</el-menu-item>
        <el-menu-item index="5" v-if="system.isOpenAllData" @click="replaceIsOpenAllData">折叠部分数据</el-menu-item>
        <el-menu-item index="5" v-else @click="replaceIsOpenAllData">展开全部数据</el-menu-item>
        <el-menu-item index="6" v-if="system.isAutoClearInput" @click="replaceIsAutoClearInput">关闭自动清空</el-menu-item>
        <el-menu-item index="6" v-else @click="replaceIsAutoClearInput">开启自动清空</el-menu-item>
        <UserHeader v-if="search.currentAuthUserId!=-1" :userId="search.currentAuthUserId" style="margin: 0px 10px 0px 10px !important;"></UserHeader>
        <el-select :remoteMethod="remoteMethod" filterable remote v-model="search.currentAuthUserId" style="width: 190px;margin-right:10px;paddingTop:18px;" size="mini" placeholder="请选择角色">
            <el-option label="未选择用户" :value="-1"></el-option>
            <el-option v-for="item in queryUsers" :label="item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''" :value="item.userId">
                <UserHeader headerSize="small" v-if="item.userId!=-1" :userId="item.userId"></UserHeader>
                <div style="display:inline-block;margin-left:5px;"> {{ ' 昵称：'+item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''}}</div>

            </el-option>
        </el-select>

    </el-menu>
    <EditGoods :id="business.editId" @updatePage="this.updatePage" ref="editGoods"></EditGoods>
    <el-dialog title="封禁用户" :before-close="clearInput" :visible.sync="dialogVisibleMul" width="30%">

        <div>
            <el-form ref="form" label-width="80px" size="mini">
                <el-form-item label="封禁用户">
                    <UserHeader v-for="item in multipleSelection" :userId="item.userId" style="margin-right:10px"></UserHeader>
                </el-form-item>
                <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="封禁原因">
                    <el-input v-model="banReason"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmBanMul">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑商品" :before-close="clearInput" :visible.sync="dialogVisibleEdit" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                            <UserHeader :userId="currentBanUserId"></UserHeader>
                        </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                            <el-col :span="11">
                                <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                            </el-col>
                        </el-form-item> -->
                <el-form-item label="商品地址">
                    <el-input v-model="currentAuthUrl"></el-input>
                </el-form-item>
                <el-form-item label="商品名">
                    <el-input placeholder="请输入商品名" v-model="editAuthName"></el-input>
                </el-form-item>
                <!-- <el-form-item label="角色中文名">
                        <el-input placeholder="请输入角色中文名" v-model="editRoleChineseName"></el-input>
                    </el-form-item> -->
                <el-form-item label="商品描述">
                    <el-input placeholder="请输入商品描述" v-model="editAuthDes"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmEdit">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog title="新增商品" :before-close="clearInput" :visible.sync="dialogVisible" width="70%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                            <UserHeader :userId="currentBanUserId"></UserHeader>
                        </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                            <el-col :span="11">
                                <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                            </el-col>
                        </el-form-item> -->
                <el-form-item label="商品名称">
                    <el-input placeholder="请输入商品名称" v-model="add.goodsName"></el-input>
                </el-form-item>
                <el-form-item label="商品类别">
                    <el-select select-when-unmatched :remoteMethod="remoteQueryGoodsCategoryMethod" filterable remote v-model="add.goodsCategoryId" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择类别">
                        <el-option label="未选择商品类别" :value="-1"></el-option>
                        <el-option v-for="item in add.frontClientUsing.goodsCategoryList" :label="item.goodsCategoryName" :value="item.goodsCategoryId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品成本">
                    <el-input placeholder="请输入商品成本（卖出价不会低于此价格）" v-model="add.goodsLowPrice"></el-input>
                </el-form-item>
                <el-form-item label="商品基本价格">
                    <el-input placeholder="请输入商品基本价格（未给特定用户设定价格时，默认用户看到的价格）" v-model="add.goodsBasePrice"></el-input>
                </el-form-item>
                <el-form-item label="商品最大库存">
                    <el-input placeholder="请输入商品最大库存（用于真实记录库存数）" v-model="add.goodsMaxRepertory"></el-input>
                </el-form-item>
                <el-form-item label="商品放出库存">
                    <el-input placeholder="请输入商品放出库存（若需要囤货不完全放出全部产品数，可以设置低于最大库存数）" v-model="add.goodsAllowRepertory"></el-input>
                </el-form-item>
                <el-form-item label="商品单位">
                    <el-input placeholder="请输入商品单位（斤、瓶、箱等等）" v-model="add.goodsUnit"></el-input>
                </el-form-item>
                <el-form-item label="商品规格">
                    <el-input placeholder="请输入商品规格（500ml*6、25kg*1）" v-model="add.goodsSpecification"></el-input>
                </el-form-item>
                <el-form-item label="商品描述">
                    <el-input placeholder="请输入商品描述" v-model="add.goodsDescribe"></el-input>
                </el-form-item>
                <el-form-item prop="imageUrl" label="上传商品图片">
                    <el-upload :on-remove="onRemove" :on-exceed="onExceed" :limit="1" list-type='picture' :data="{...add,frontClientUsing: '仅供前端使用，无需在乎此变量' }" :drag="true" accept="jpg" :file-list="add.frontClientUsing.fileList" :on-change="onChange" ref="upload" class="avatar-uploader" :auto-upload="false" name="file" action="/api/goodsManage/addGoods" :show-file-list="true" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <el-image fit="cover" :style="'width: '+360+'px; height: '+180+'px'" v-if="add.frontClientUsing.imageUrl" :src="add.frontClientUsing.imageUrl" class="avatar"></el-image>
                        <i class="el-icon-plus avatar-uploader-icon" style="border: 0px dotted gray;border-radius:10px"></i>
                    </el-upload>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmAdd">确 定</el-button>
        </span>
    </el-dialog>
    <el-table ref="multipleTable" :data="search.tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>

        <el-table-column label="商品图片" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
                <!-- {{ scope.row.goodsImage }} -->
                <QuickImage :url="scope.row.goodsImage" :width="100" :height="100"></QuickImage>
            </template>
        </el-table-column>
        <el-table-column label="商品名称" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsName }}</template>
        </el-table-column>
        <el-table-column label="销量" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsSellCount }}</template>
        </el-table-column>
        <el-table-column label="成本" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsLowPrice+'元' }}</template>
        </el-table-column>
        <el-table-column label="基本价格" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsBasePrice+'元' }}</template>
        </el-table-column>

        <!-- <el-table-column label="创建时间时间" width="150" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column> -->

        <el-table-column label="最大库存" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsMaxRepertory }}</template>
        </el-table-column>
        <el-table-column label="放出库存" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsAllowRepertory }}</template>
        </el-table-column>

        <el-table-column label="商品单位" v-if="system.isOpenAllData" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsUnit }}</template>
        </el-table-column>
        <el-table-column label="商品规格" v-if="system.isOpenAllData" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsSpecification }}</template>
        </el-table-column>
        <el-table-column label="商品描述" v-if="system.isOpenAllData" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsDescribe }}</template>
        </el-table-column>
        <el-table-column label="商品状态" v-if="system.isOpenAllData" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsState }}</template>
        </el-table-column>
        <el-table-column label="所属商品类别" v-if="system.isOpenAllData" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.goodsCategoryVo.goodsCategoryName }}</template>
        </el-table-column>
        <el-table-column label="用户特定价格" v-if="search.currentAuthUserId!=-1" width="250">
            <template slot-scope="scope">
                <el-input placeholder="请输入用户特定价格" @keyup.enter.native="updateUserPrice(scope.row.userGoodsVo.specialPrice,search.currentAuthUserId,scope.row.goodsId)" v-model="scope.row.userGoodsVo.specialPrice" class="input-with-select">

                    <el-button style="color: #67C23A;" slot="append" icon="el-icon-check" @click="updateUserPrice(scope.row.userGoodsVo.specialPrice,search.currentAuthUserId,scope.row.goodsId)"></el-button>
                </el-input>
            </template>
        </el-table-column>
        <el-table-column label="用户特定库存" v-if="search.currentAuthUserId!=-1" width="250">
            <template slot-scope="scope">
                <el-input placeholder="请输入用户特定库存" v-model="scope.row.userGoodsVo.allowRepertory" @keyup.enter.native="updateUserAllowRepertory(scope.row.userGoodsVo.allowRepertory,search.currentAuthUserId,scope.row.goodsId)" class="input-with-select">

                    <el-button style="color: #67C23A;" slot="append" icon="el-icon-check" @click="updateUserAllowRepertory(scope.row.userGoodsVo.allowRepertory,search.currentAuthUserId,scope.row.goodsId)"></el-button>
                </el-input>
            </template>
        </el-table-column>
        <!-- <el-table-column align="right" min-width="0"> -->
        <!-- <template slot="header" slot-scope="scope"> -->
        <!-- <UserHeader v-if="search.currentAuthUserId!=-1" :userId="search.currentAuthUserId" style="margin: 0px 10px 0px 10px;"></UserHeader>
                <el-select :remoteMethod="remoteMethod" filterable remote v-model="search.currentAuthUserId" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择角色">
                    <el-option label="未选用户" :value="-1"></el-option>
                    <el-option v-for="item in queryUsers" :label="item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''" :value="item.userId"></el-option>
                </el-select> -->
        <!-- <el-input v-model="search.keyword" size="mini" placeholder="请输入商品有关信息进行筛选" /> -->
        <!-- </template> -->
        <!-- <template slot-scope="scope">
                            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        </template> -->
        <!-- </el-table-column> -->
        <!-- <el-table-column align="right" :width="search.currentAuthUserId==-1?440:500"> -->
        <el-table-column align="right" min-width="300">

            <template slot="header" slot-scope="scope">
                <!-- <UserHeader v-if="search.currentAuthUserId!=-1" :userId="search.currentAuthUserId" style="margin: 0px 10px 0px 10px;"></UserHeader>
                <el-select :remoteMethod="remoteMethod" filterable remote v-model="search.currentAuthUserId" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择角色">
                    <el-option label="未选用户" :value="-1"></el-option>
                    <el-option v-for="item in queryUsers" :label="item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''" :value="item.userId"></el-option>
                </el-select> -->
                <!-- <el-button size="mini" type="primary" @click="dialogVisible=true">新增角色</el-button> -->

                <!-- <el-button size="mini" type="primary" @click="dialogVisible=true">新增商品</el-button> -->
                <!-- <el-button size="mini" type="danger" v-if="system.isOpenAllData" @click="deleteAuths">批量删除商品</el-button> -->
                <!-- <el-button size="mini" type="success" v-if="system.isOpenAllData" @click="deleteAuths">批量上架商品</el-button> -->

                <!-- <el-button size="mini" type="danger" v-if="system.isOpenAllData" @click="deleteAuths">批量下架商品</el-button> -->

                <el-input v-model="search.keyword" size="mini" placeholder="请输入商品有关信息进行筛选" />

                <!-- <el-button size="mini" type="success" v-if="system.isAutoClearInput" @click="replaceIsAutoClearInput">关闭自动清空</el-button>
                <el-button size="mini" type="danger" v-else @click="replaceIsAutoClearInput">开启自动清空</el-button>

                <el-button size="mini" type="danger" v-if="system.isOpenAllData" @click="replaceIsOpenAllData">折叠部分数据</el-button>
                <el-button size="mini" type="primary" v-else @click="replaceIsOpenAllData">展开全部数据</el-button> -->

                <!-- <el-button size="mini" type="success" v-if="search.currentAuthUserId!=-1" @click="authAll">批量授权</el-button>
                <el-button size="mini" type="danger" v-if="search.currentAuthUserId!=-1" @click="unAuthAll">批量取消</el-button> -->

            </template>
            <template slot-scope="scope">
                <el-button size="mini" type="danger" v-if="scope.row.goodsState=='已上架'" @click="downOne(scope.row.goodsId)">下架</el-button>
                <el-button size="mini" type="success" v-else @click="upOne(scope.row.goodsId)">上架</el-button>
                <el-button size="mini" @click="openEditGoods(scope.row.goodsId)">编辑</el-button>
                <el-button size="mini" type="danger" @click="deleteOne(scope.row.goodsId)">删除</el-button>

                <!-- <el-button size="mini" v-if="scope.row.currentRoleIsAuth" type="danger" @click="unAuthOne(scope.row.goodsId)">取消授权</el-button>
                <el-button size="mini" v-else-if="search.currentAuthUserId!=-1" type="success" @click="authOne(scope.row.goodsId)">授权</el-button> -->

            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[4,10,15,20]" :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="search.dataTotal">
        </el-pagination>

    </div>

</div>
</template>

<script>
import QuickImage from '@/components/image/QuickImage.vue';
import UserHeader from '@/components/business/user/UserHeader.vue';
import dateUtils from '@/utils/dateUtils';
import EditGoods from '@/components/business/goodsBusiness/goodsManage/EditGoods.vue';
export default {
    name: 'GoodsManage',
    components: {
        UserHeader,
        QuickImage,
        EditGoods
    },
    data() {
        return {
            business: {
                editId: -1
            },
            add: {
                goodsName: '',
                goodsCategoryId: -1,
                goodsLowPrice: '',
                goodsBasePrice: '',
                goodsMaxRepertory: '',
                goodsAllowRepertory: '',
                goodsUnit: '',
                goodsSpecification: '',
                goodsDescribe: '',
                frontClientUsing: {
                    imageUrl: '',
                    fileList: [],
                    goodsCategoryList: []
                }
            },
            system: {
                isAutoClearInput: true,
                isOpenAllData: false
            },
            search: {
                keyword: '',
                currentAuthUserId: -1,
                tableData: [],
                dataTotal: 0,
                pageSize: 4,
            },

            multipleSelection: [],
            currentPage: 1,

            date: '',
            time: '',
            banReason: '',
            dialogVisibleEdit: false,
            dialogVisible: false,
            dialogVisibleMul: false,
            currentBanUserId: -1,
            addAuthName: '',
            addAuthUrl: '',
            addAuthComment: '',
            editRoleName: '',
            editRoleChineseName: '',
            editRoleDes: '',
            currentEditId: -1,
            currentAuthUserId: -1,
            allRoles: [],
            editAuthName: '',
            editAuthDes: '',
            currentAuthUrl: '',
            queryUsers: [],
            imageUrl: '',
            fileList: [],

        };
    },
    watch: {
        'search.keyword': {
            deep: true,
            handler(newName, oldName) {
                this.updatePage();
            }
        },
        'search.currentAuthUserId': {
            deep: true,
            handler(newName, oldName) {
                this.updatePage();
            }
        },
        'search.pageSize'() {
            this.updatePage();
        },
        currentPage() {
            this.updatePage();
        }
    },
    mounted() {

        this.updatePage();

    },
    created() {

    },
    methods: {
        //更新用户特定库存
        updateUserAllowRepertory(allowRepertory, currentAuthUserId, goodsId) {
            let that = this;
            that.$apis.saveUserGoodsAllowRepertory({
                allowRepertory,
                goodsId,
                userId: currentAuthUserId
            }).then(res => {
                if (res.msg == '操作成功') {
                    that.$message({
                        type: 'success',
                        message: '更新用户特定库存数成功'
                    })
                    that.updatePage();
                }
            })
            // console.log(allowRepertory, currentAuthUserId, goodsId)
        },
        //更新用户特定价格
        updateUserPrice(specialPrice, currentAuthUserId, goodsId) {
            let that = this;
            that.$apis.saveUserGoodsSpecialPrice({
                specialPrice,
                goodsId,
                userId: currentAuthUserId
            }).then(res => {
                if (res.msg == '操作成功') {
                    that.$message({
                        type: 'success',
                        message: '更新用户特定价格成功'
                    })
                    that.updatePage();
                }
            })
            // console.log(specialPrice, currentAuthUserId, goodsId)
        },
        openEditGoods(id) {
            this.business.editId = id;
            this.$refs.editGoods.open();
        },
        //替换是否自动清空输入
        replaceIsAutoClearInput() {
            this.system.isAutoClearInput = !this.system.isAutoClearInput;
        },
        //替换是否打开全部数据
        replaceIsOpenAllData() {
            this.system.isOpenAllData = !this.system.isOpenAllData;
        },
        //上传图片代码
        onRemove(files, fileList) {
            this.add.frontClientUsing.fileList = fileList;
            // this.$refs.form.validate((res) => {});
        },
        checkIsUploadHeader(rule, value, callback) {

            if (this.add.frontClientUsing.fileList.length == 1) {
                callback();
            } else {
                callback(new Error('未上传图片'));
            }
        },
        onExceed(files, fileList) {
            this.$message({
                message: '最多选择上传一个图片',
                type: 'error'
            })
        },
        onChange(file, fileList) {
            let that = this;

            if (file.response != undefined && file.response != null) {
                //已完成上传
                if (file.response.msg == '操作成功') {
                    that.$message({
                        type: 'success',
                        message: '商品添加成功'

                    })
                    that.updatePage();
                    that.clearInput();
                } else {
                    that.$message({
                        type: 'error',
                        message: file.response.msg
                    })
                }
                that.add.frontClientUsing.fileList = [];
                that.add.frontClientUsing.imageUrl = "";

            } else {
                //未上传，仅修改需要上传的内容
                that.add.frontClientUsing.fileList = fileList;
                // that.$refs.form.validate((res) => {});
                that.add.frontClientUsing.imageUrl = URL.createObjectURL(file.raw);
            }
        },
        handleAvatarSuccess(res, file) {
            // this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isPicture = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png');
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isPicture) {
                this.$message.error('请上传 jpg、jpeg、png 格式的图片!');
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
            }
            return isPicture && isLt10M;
        },
        deleteMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将批量删除商品 , 是否继续?', '删除商品提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteGoodses({
                        ids: that.multipleSelection.map(item => item.goodsId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            that.$message({
                                type: 'success',
                                message: '商品删除成功'
                            })
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择需要删除的商品'
                })
            }
        },
        deleteOne(val) {
            let that = this;
            this.$confirm('此操作将删除商品 , 是否继续?', '删除商品提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteGoodses({
                    ids: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        that.$message({
                            type: 'success',
                            message: '商品删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        upMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将批量上架商品 , 是否继续?', '上架商品提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.goodsesUp({
                        ids: that.multipleSelection.map(item => item.goodsId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            that.$message({
                                type: 'success',
                                message: '商品上架成功'
                            })
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消上架'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择需要上架的商品'
                })
            }
        },
        upOne(val) {
            let that = this;
            console.log("上架");
            this.$confirm('此操作将上架该商品 , 是否继续?', '上架商品提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.goodsesUp({
                    ids: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        that.$message({
                            type: 'success',
                            message: '商品上架成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消上架'
                });
            });

        },
        downMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将批量下架商品 , 是否继续?', '下架商品提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.goodsesDown({
                        ids: that.multipleSelection.map(item => item.goodsId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            that.$message({
                                type: 'success',
                                message: '商品下架成功'
                            })
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消下架'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择需要下架的商品'
                })
            }
        },
        downOne(val) {
            let that = this;
            console.log("下架");
            this.$confirm('此操作将下架该商品 , 是否继续?', '下架商品提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.goodsesDown({
                    ids: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        that.$message({
                            type: 'success',
                            message: '商品下架成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消下架'
                });
            });

        },
        remoteQueryGoodsCategoryMethod(query) {
            let that = this;
            // console.log(query);
            this.$apis.searchGoodsCategory({
                keyword: query,
                pageNum: 1,
                pageSize: 20,
                orderByColumn: 'update_time',
                isAsc: "desc"
            }).then(res => {
                // console.log(res.rows);
                that.add.frontClientUsing.goodsCategoryList = res.rows;
            })
        },
        remoteMethod(query) {
            let that = this;
            // console.log(query);
            this.$apis.queryUserVos({
                username: query,
                pageNum: 1,
                pageSize: 10
            }).then(res => {
                // console.log(res.rows);
                that.queryUsers = res.rows;
            })
        },
        authOne(val) {
            let that = this;
            this.$confirm('此操作将授权 , 是否继续?', '授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.search.currentAuthUserId,
                    authIds: [val],
                    authOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授权'
                });
            });
        },
        authAll() {
            let that = this;
            this.$confirm('此操作将授权 , 是否继续?', '授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.search.currentAuthUserId,
                    authIds: that.multipleSelection.map(item => item.authId),
                    authOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授权'
                });
            });
        },
        unAuthAll() {
            let that = this;
            this.$confirm('此操作将取消授权  是否继续?', '取消授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.search.currentAuthUserId,
                    authIds: that.multipleSelection.map(item => item.authId),
                    authOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授权'
                });
            });
        },
        unAuthOne(val) {
            let that = this;
            this.$confirm('此操作将取消授权 , 是否继续?', '取消授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.search.currentAuthUserId,
                    authIds: [val],
                    authOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授权'
                });
            });
        },
        confirmEdit() {
            let that = this;
            if (that.currentAuthUrl.length >= 2 && that.currentAuthUrl.length < 200) {
                if (that.editAuthName.length >= 2 && that.editAuthName.length < 200) {
                    if (that.editAuthDes.length >= 2 && that.editAuthDes.length < 200) {

                        that.$apis.editAuth({
                            authId: that.currentEditId,
                            authUrl: that.currentAuthUrl,
                            authName: that.editAuthName,
                            authComment: that.editAuthDes
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.updatePage();
                                that.$message({
                                    type: 'success',
                                    message: '编辑商品成功'
                                })
                                that.clearInput();

                            }
                        })

                    } else {
                        that.$message({
                            type: 'error',
                            message: '商品描述要在2-200字符之间'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '商品名要在2-200字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '商品地址要在2-200字符之间'
                })
            }
        },
        edit(val) {
            this.currentEditId = val;
            let that = this;
            this.$apis.queryAuthById({
                authId: val
            }).then(res => {
                if (res.msg == '操作成功') {
                    that.editAuthName = res.data.authName ? res.data.authName : '';
                    that.editAuthDes = res.data.authComment ? res.data.authComment : '';
                    that.dialogVisibleEdit = true;
                    that.currentAuthUrl = res.data.authUrl ? res.data.authUrl : '';
                }
            })
        },
        deleteRoleMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteRoles({
                        roleIds: that.multipleSelection.map(item => item.roleId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                            that.multipleSelection = [];
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择角色'
                })
            }
        },
        deleteRole(val) {
            let that = this;
            this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteRoles({
                    roleIds: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        confirmAdd() {
            if (this.add.frontClientUsing.fileList.length == 0) {
                this.$message({
                    type: 'error',
                    message: '未上传图片'
                })
            } else {
                this.$refs.upload.submit();

            }

        },
        addAuth() {
            let that = this;
            if (that.addAuthName.length >= 2 && that.addAuthName.length < 200) {
                if (that.addAuthUrl.length >= 2 && that.addAuthUrl.length < 200) {
                    if (that.addAuthComment.length >= 2 && that.addAuthComment.length < 200) {
                        that.$apis.addAuth({
                            authName: that.addAuthName,
                            authUrl: that.addAuthUrl,
                            authComment: that.addAuthComment
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.updatePage();
                                that.$message({
                                    type: 'success',
                                    message: '添加角色成功'
                                })
                                that.clearInput();

                            }
                        })
                    } else {
                        that.$message({
                            type: 'error',
                            message: '商品备注要在2-200字符之间'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '商品地址要在2-200字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '商品名要在2-200字符之间'
                })
            }

        },

        banMul() {
            if (this.multipleSelection.length > 0) {
                this.dialogVisibleMul = true;
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择用户'
                })
            }
        },
        //清空输入
        clearInput() {
            this.dialogVisible = false;
            this.dialogVisibleMul = false;
            this.dialogVisibleEdit = false;
            if (this.system.isAutoClearInput) {
                //清空添加
                this.add = {
                    ...this.add,
                    goodsName: '',
                    goodsCategoryId: -1,
                    goodsLowPrice: '',
                    goodsBasePrice: '',
                    goodsMaxRepertory: '',
                    goodsAllowRepertory: '',
                    goodsUnit: '',
                    goodsSpecification: '',
                    goodsDescribe: '',
                    frontClientUsing: {
                        imageUrl: '',
                        fileList: [],
                        goodsCategoryList: this.add.frontClientUsing.goodsCategoryList
                    }
                }

                this.addAuthName = '';
                this.addAuthUrl = '';
                this.addAuthComment = '';
                this.editRoleName = '';
                this.editRoleChineseName = '';
                this.editRoleDes = '';

                this.editAuthDes = '';
                this.editAuthName = '';
                this.currentEditId = -1;
                this.currentAuthUrl = '';
                // done();
            }
        },

        confirmBanMul() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        for (let i = 0; i < that.multipleSelection.length; i++) {
                            that.$apis.banUser({
                                availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                                userId: that.multipleSelection[i].userId,
                                denyReason: that.banReason
                            }).then(res => {
                                if (res.msg = "操作成功") {
                                    that.$message({
                                        type: 'success',
                                        message: '禁用用户(' + that.multipleSelection[i].nickname + ')成功'
                                    })
                                    if (i == that.multipleSelection.length - 1) {
                                        that.updatePage();
                                        that.clearInput();
                                        that.dialogVisibleMul = false;
                                    }
                                }
                            })
                        }

                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        confirmBan() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        that.$apis.banUser({
                            availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                            userId: that.currentBanUserId,
                            denyReason: that.banReason
                        }).then(res => {
                            if (res.msg = "操作成功") {
                                that.updatePage();
                                that.clearInput();
                                that.$message({
                                    type: 'success',
                                    message: '禁用用户成功'
                                })
                                that.dialogVisible = false;

                            }
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        ban(val) {
            this.currentBanUserId = val;
            this.dialogVisible = true;
        },
        // flattenObject(obj) {
        //     return Object.assign({}, ... function _flatten(childObj, prefix = '') {
        //         return [].concat(
        //             ...Object.keys(childObj).map(
        //                 key => typeof childObj[key] === 'object' ? _flatten(childObj[key], `${prefix}${key}.`) : {
        //                     [`${prefix}${key}`]: childObj[key]
        //                 }
        //             )
        //         );
        //     }(obj));
        // },
        updatePage() {
            // console.log("更新啊");
            let that = this;
            // console.log(111, this.search)
            // let test = {
            //     keyword: this.search.keyword,
            //     userId: that.search.currentAuthUserId,
            //     pageQuery: {
            //         pageNum: this.currentPage,
            //         pageSize: this.search.pageSize,
            //         orderByColumn: 'update_time',
            //         isAsc: 'desc'
            //     }
            // }
            // console.log(123, this.flattenObject(test))

            this.$apis.searchGoods(this.$flattenObject({
                keyword: this.search.keyword,
                userId: that.search.currentAuthUserId,
                pageQuery: {
                    pageNum: this.currentPage,
                    pageSize: this.search.pageSize,
                    orderByColumn: 'update_time',
                    isAsc: 'desc'
                }
            })).then(res => {
                // console.log(res);
                // that.search.tableData = res.rows;
                that.$set(that.search, 'tableData', res.rows);
                that.search.dataTotal = res.total;
            })

            //更新页面时自动远程调用
            this.remoteMethod("");
            this.remoteQueryGoodsCategoryMethod("");
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.search.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
        }
    },
};
</script>

<style scoped>
/**上传图片样式*/
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
