<template>
<div style="display: inline;">
    <el-dialog title="用户角色编辑" :visible.sync="dialogVisible" width="90%">

        <div>
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>

                <el-table-column label="角色编号" width="80" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.roleId }}</template>
                </el-table-column>
                <el-table-column label="角色名" width="90" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.roleName }}</template>
                </el-table-column>
                <el-table-column label="角色中文名" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.roleChineseName }}</template>
                </el-table-column>
                <el-table-column label="角色备注" width="280" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.roleComment }}</template>
                </el-table-column>
                <el-table-column label="最近编辑时间" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.updateTime }}</template>
                </el-table-column>

                <el-table-column align="right" min-width="200">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="请输入角色编号、角色中文名、角色名、角色备注" />
                    </template>
                    <!-- <template slot-scope="scope">
                                <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            </template> -->
                </el-table-column>
                <el-table-column align="right" :width="250">
                    <template slot="header" slot-scope="scope">
                        <!-- <el-select v-model="currentAuthRole" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择角色">
                            <el-option label="未选角色" :value="-1"></el-option>
                            <el-option v-for="item in allRoles" :label="item.roleChineseName+'('+item.roleName+')'" :value="item.roleId"></el-option>
                        </el-select> -->
                        <!-- <el-button size="mini" type="primary" @click="dialogVisible=true">新增角色</el-button> -->
                        <el-button size="mini" @click="userGetRoleAll" type="success">批量授予角色</el-button>
                        <el-button size="mini" @click="unUserGetRoleAll" type="danger">批量取消角色</el-button>

                    </template>
                    <template slot-scope="scope">
                        <!-- <el-button size="mini" @click="edit(scope.row.authId)">编辑</el-button> -->
                        <el-button size="mini" v-if="scope.row.isGetRole" @click="unUserGetRoleOne(scope.row.roleId)" type="danger">取消授予角色</el-button>
                        <el-button size="mini" v-else type="success" @click="userGetRoleOne(scope.row.roleId)">授予角色</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <!-- <div style=" margin-top: 20px">
                            <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
                            <el-button @click="toggleSelection()">取消选择</el-button>
        </div> -->

            <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5,10,15,20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
                </el-pagination>

            </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button>取 消</el-button>
            <el-button type="primary">确 定</el-button>
        </span> -->
    </el-dialog>
    <el-popover class="headerIcon" v-if="user.headerUrl" placement="top-start" title="" width="800" trigger="hover">
        <div>
            <el-descriptions class="margin-top" title="" :column="2" size="mini" border>
                <!-- <template slot="extra">
                    <el-button type="primary" size="small">操作</el-button>
                </template> -->
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-star-off"></i>
                        用户编号
                    </template>
                    {{user.userId}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        用户名
                    </template>
                    {{user.username}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        昵称
                    </template>
                    {{user.nickname}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span v-if="user.gender=='女'">
                            <i class="el-icon-female"></i>
                            性别
                        </span>
                        <span v-else>
                            <i class="el-icon-male"></i>
                            性别
                        </span>
                    </template>
                    {{user.gender}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-document"></i>
                        最近封禁原因
                    </template>
                    {{user.denyReason}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-watch-1"></i>
                        最近解封时间
                    </template>
                    {{user.availableTime}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-phone-outline"></i>
                        联系方式
                    </template>
                    {{user.phone}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-date"></i>
                        出生时间
                    </template>
                    {{user.dayOfBirth}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-timer"></i>
                        账号创建时间
                    </template>
                    {{user.createTime}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-watch"></i>
                        账号最近编辑时间
                    </template>
                    {{user.updateTime}}
                </el-descriptions-item>

                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-watch"></i>
                        用户角色
                    </template>
                    <el-tag style="margin: 5px 8px 5px 2px;" type="danger" v-for="item in user.roleVos">{{item.roleChineseName+'('+ item.roleName +')'}}</el-tag>
                    <el-button style="margin: 5px 8px 5px 2px;" type="danger" size="mini" @click="editUserGetRole">编辑用户角色</el-button>

                </el-descriptions-item>
                <!-- <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        最近账号信息编辑者
                    </template>
                    <UserHeader v-if="user.updateBy" :userId="user.updateBy"></UserHeader>
                    <span v-else>暂无编辑者</span>
                </el-descriptions-item> -->
            </el-descriptions>

        </div>
        <el-button slot="reference">
            <el-avatar shape="square" :size="headerSize" :src="'/api/file/downloadImage/'+user.headerUrl"></el-avatar>
        </el-button>
    </el-popover>

</div>
</template>

<script>
export default {
    name: 'UserHeader',
    components: {},
    data() {
        return {
            user: {

            },
            dialogVisible: false,
            tableData: [],
            currentPage: 1,
            pageSize: 5,
            dataTotal: 0,
            search: '',
            multipleSelection: [],
        };
    },
    props: {
        userId: {
            require: true
        },
        headerSize: {
          
            default: 'medium'
        }
    },
    watch: {
        userId: {
            immediate: true,
            handler(newVal, oldVal) {
                this.updatePage();
            }
        },
        headerSize: {
            immediate: true,
            handler(newVal, oldVal) {
                this.updatePage();
            }
        },
        search() {
            this.updatePage();
        },
        pageSize() {
            this.updatePage();
        },
        currentPage() {
            this.updatePage();
        }
    },
    mounted() {

    },
    methods: {
        userGetRoleOne(val) {
            let that = this;
            this.$confirm('此操作将授予该角色给 ' + that.user.nickname + '(' + that.user.username + '), 是否继续?', '授予角色提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userGetRoleOrNot({
                    userId: that.userId,
                    roleIds: [val],
                    getOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授予角色成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授予角色'
                });
            });
        },
        userGetRoleAll() {
            let that = this;
            this.$confirm('此操作将授予该角色给 ' + that.user.nickname + '(' + that.user.username + '), 是否继续?', '授予角色提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userGetRoleOrNot({
                    userId: that.userId,
                    roleIds: that.multipleSelection.map(item => item.roleId),
                    getOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授予角色成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授予角色'
                });
            });
        },
        unUserGetRoleAll() {
            let that = this;
            this.$confirm('此操作将取消授予该角色给 ' + that.user.nickname + '(' + that.user.username + '), 是否继续?', '取消授予角色提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userGetRoleOrNot({
                    userId: that.userId,
                    roleIds: that.multipleSelection.map(item => item.roleId),
                    getOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授予角色成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授予角色'
                });
            });
        },
        unUserGetRoleOne(val) {
            let that = this;
            this.$confirm('此操作将取消授予该角色给 ' + that.user.nickname + '(' + that.user.username + '), 是否继续?', '取消授予角色提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userGetRoleOrNot({
                    userId: that.userId,
                    roleIds: [val],
                    getOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授予角色成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授予角色'
                });
            });
        },
        updatePage() {
            let that = this;
            let userId = this.userId;
            if (userId != '' && userId != undefined && userId > 0) {
                this.$apis.searchUserVoByUserId({
                    userId: this.userId
                }).then(res => {
                    // console.log(res);
                    if (res.msg == '操作成功') {
                        this.user = res.data;
                    }
                })
                this.$apis.queryUserGetRole({
                    userId: userId,
                    pageNum: that.currentPage,
                    pageSize: that.pageSize,
                    roleChineseName: that.search
                }).then(res => {
                    // console.log(res);
                    if (res.msg == "查询成功") {
                        that.tableData = res.rows;
                        that.dataTotal = res.total;
                    }
                })
            }
        },
        editUserGetRole() {
            this.updatePage();
            this.dialogVisible = true;
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
        }
    },
};
</script>

<style scoped>
.headerIcon * {
    margin: 0;
    padding: 0;
    border: 0;
}
</style>
