<template>
<div>

    <el-dialog title="封禁用户" :before-close="clearInput" :visible.sync="dialogVisibleMul" width="30%">

        <div>
            <el-form ref="form" label-width="80px" size="mini">
                <el-form-item label="封禁用户">
                    <UserHeader v-for="item in multipleSelection" :userId="item.userId" style="margin-right:10px"></UserHeader>
                </el-form-item>
                <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="封禁原因">
                    <el-input v-model="banReason"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmBanMul">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="编辑字典" :before-close="clearInput" :visible.sync="dialogVisibleEdit" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                        <UserHeader :userId="currentBanUserId"></UserHeader>
                    </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item> -->

                <el-form-item label="字典键">
                    <el-input placeholder="请输入字典键" v-model="editDictName"></el-input>
                </el-form-item>
                <el-form-item label="字典值">
                    <el-input placeholder="请输入字典值" v-model="editDictValue"></el-input>
                </el-form-item>
                <el-form-item label="字典类型" prop="region">
                    <el-select v-model="editDictType" placeholder="请选择活动区域">
                        <el-option label="字符串(string) 格式 : [hello world]" value="string"></el-option>
                        <el-option label="数字类型(number) 格式 : [16.88]" value="number"></el-option>
                        <el-option label="布尔类型(boolean) 格式 : [true,false]" value="boolean"></el-option>
                        <el-option label="日期类型(date) 格式 : [2022-06-08 00:00:00]" value="date"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="字典备注">
                    <el-input placeholder="请输入字典备注" v-model="editDictComment"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmEdit">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="新增字典" :before-close="clearInput" :visible.sync="dialogVisible" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                        <UserHeader :userId="currentBanUserId"></UserHeader>
                    </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item> -->
                <el-form-item label="字典键">
                    <el-input placeholder="请输入字典键" v-model="addDictName"></el-input>
                </el-form-item>
                <el-form-item label="字典值">
                    <el-input placeholder="请输入字典值" v-model="addDictValue"></el-input>
                </el-form-item>
                <el-form-item label="字典类型" prop="region">
                    <el-select v-model="addDictType" placeholder="请选择活动区域">
                        <el-option label="字符串(string) 格式 : [hello world]" value="string"></el-option>
                        <el-option label="数字类型(number) 格式 : [16.88]" value="number"></el-option>
                        <el-option label="布尔类型(boolean) 格式 : [true,false]" value="boolean"></el-option>
                        <el-option label="日期类型(date) 格式 : [2022-06-08 00:00:00]" value="date"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="字典备注">
                    <el-input placeholder="请输入字典备注" v-model="addDictComment"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="addDict">确 定</el-button>
        </span>
    </el-dialog>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>

        <el-table-column label="字典编号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dictId }}</template>
        </el-table-column>
        <el-table-column label="字典键" width="350" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dictKey }}</template>
        </el-table-column>
        <el-table-column label="字典值" width="200" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dictValue }}</template>
        </el-table-column>
        <el-table-column label="字典类型" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dictType }}</template>
        </el-table-column>
        <el-table-column label="字典备注" width="250" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dictComment }}</template>
        </el-table-column>
        <!-- <el-table-column label="创建时间时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column> -->
        <el-table-column label="最近编辑时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateTime }}</template>
        </el-table-column>
        <el-table-column align="right" min-width="200">
            <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="请输入字典编号、字典键、字典值、字典类型、字典备注" />
            </template>
            <!-- <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    </template> -->
        </el-table-column>
        <el-table-column align="right" :width="currentAuthUserId==-1?220:665">
            <template slot="header" slot-scope="scope">
                <!-- <UserHeader v-if="currentAuthUserId!=-1" :userId="currentAuthUserId" style="margin: 0px 10px 0px 10px;"></UserHeader>
                <el-select :remoteMethod="remoteMethod" filterable remote v-model="currentAuthUserId" style="width: 190px;margin-right:10px" size="mini" placeholder="请选择角色">
                    <el-option label="未选用户" :value="-1"></el-option>
                    <el-option v-for="item in queryUsers" :label="item.nickname+' -|- 用户Id：'+item.userId+''+' -|- 用户名：'+item.username+''" :value="item.userId"></el-option>
                </el-select> -->
                <!-- <el-button size="mini" type="primary" @click="dialogVisible=true">新增角色</el-button> -->

                <el-button size="mini" type="primary" @click="dialogVisible=true">新增字典</el-button>
                <el-button size="mini" type="danger" @click="deleteMul">批量删除字典</el-button>

                <!-- <el-button size="mini" type="success" v-if="currentAuthUserId!=-1" @click="authAll">批量授权</el-button>
                <el-button size="mini" type="danger" v-if="currentAuthUserId!=-1" @click="unAuthAll">批量取消</el-button> -->

            </template>
            <template slot-scope="scope">
                <el-button size="mini" @click="edit(scope.row.dictId)">编辑</el-button>
                <el-button size="mini" type="danger" @click="deleteOne(scope.row.dictId)">删除</el-button>

                <el-button size="mini" v-if="scope.row.currentRoleIsAuth" type="danger" @click="unAuthOne(scope.row.authId)">取消授权</el-button>
                <el-button size="mini" v-else-if="currentAuthUserId!=-1" type="success" @click="authOne(scope.row.authId)">授权</el-button>

            </template>
        </el-table-column>
    </el-table>
    <!-- <div style="margin-top: 20px">
                <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
                <el-button @click="toggleSelection()">取消选择</el-button>
            </div> -->

    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5,10,15,20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
        </el-pagination>

    </div>

</div>
</template>

<script>
import UserHeader from '@/components/business/user/UserHeader.vue';
import dateUtils from '@/utils/dateUtils';
export default {
    name: 'AuthManage',
    components: {
        UserHeader
    },
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            search: '',
            currentPage: 1,
            pageSize: 10,
            dataTotal: 0,
            search: '',
            date: '',
            time: '',
            banReason: '',
            dialogVisibleEdit: false,
            dialogVisible: false,
            dialogVisibleMul: false,
            currentBanUserId: -1,
            addDictName: '',
            addDictValue: '',
            addDictComment: '',
            addDictType: '',
            editRoleName: '',
            editRoleChineseName: '',
            editRoleDes: '',
            currentEditId: -1,
            currentAuthUserId: -1,
            allRoles: [],
            editDictName: '',
            editDictValue: '',
            editDictComment: '',
            editDictType: '',
            currentAuthUrl: '',
            queryUsers: []
        };
    },
    watch: {
        currentAuthUserId() {
            this.updatePage();
        },
        search() {
            this.updatePage();
        },
        pageSize() {
            this.updatePage();
        },
        currentPage() {
            this.updatePage();
        }
    },
    mounted() {
        this.updatePage();

    },
    created() {
        this.remoteMethod("");
    },
    methods: {
        deleteMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将批量删除字典 , 是否继续?', '删除字典提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteDict({
                        ids: that.multipleSelection.map(item => item.dictId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            that.$message({
                                type: 'success',
                                message: '字典删除成功'
                            })
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择需要删除的字典'
                })
            }
        },
        deleteOne(val) {
            let that = this;
            this.$confirm('此操作将删除字典 , 是否继续?', '删除字典提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteDict({
                    ids: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        that.$message({
                            type: 'success',
                            message: '字典删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        remoteMethod(query) {
            let that = this;
            // console.log(query);
            this.$apis.queryUserVos({
                username: query,
                pageNum: 1,
                pageSize: 10
            }).then(res => {
                // console.log(res.rows);
                that.queryUsers = res.rows;
            })
        },
        authOne(val) {
            let that = this;
            this.$confirm('此操作将授权 , 是否继续?', '授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: [val],
                    authOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授权'
                });
            });
        },
        authAll() {
            let that = this;
            this.$confirm('此操作将授权 , 是否继续?', '授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: that.multipleSelection.map(item => item.authId),
                    authOrNot: true

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消授权'
                });
            });
        },
        unAuthAll() {
            let that = this;
            this.$confirm('此操作将取消授权  是否继续?', '取消授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: that.multipleSelection.map(item => item.authId),
                    authOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授权'
                });
            });
        },
        unAuthOne(val) {
            let that = this;
            this.$confirm('此操作将取消授权 , 是否继续?', '取消授权提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.userChangeAuth({
                    userId: that.currentAuthUserId,
                    authIds: [val],
                    authOrNot: false

                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '取消授权成功'
                        })
                        that.multipleSelection = [];
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消授权'
                });
            });
        },
        confirmEdit() {
            let that = this;

            if (that.editDictName.length >= 2 && that.editDictName.length < 250) {
                if (that.editDictValue.length >= 2 && that.editDictValue.length < 250) {
                    if (that.editDictType != '') {
                        if (that.editDictComment.length >= 2 && that.editDictComment.length < 250) {
                            that.$apis.editDict({
                                dictId: that.currentEditId,
                                dictKey: that.editDictName,
                                dictValue: that.editDictValue,
                                dictType: that.editDictType,
                                dictComment: that.editDictComment
                            }).then(res => {
                                if (res.msg == '操作成功') {
                                    that.updatePage();
                                    that.$message({
                                        type: 'success',
                                        message: '编辑字典成功'
                                    })
                                    that.clearInput();

                                }
                            })
                        } else {
                            that.$message({
                                type: 'error',
                                message: '字典备注要在2-250字符之间'
                            })
                        }
                    } else {
                        that.$message({
                            type: 'error',
                            message: '未选择字典类型'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '字典描述要在2-250字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '字典键要在2-250字符之间'
                })
            }

        },
        edit(val) {
            this.currentEditId = val;
            let that = this;
            this.$apis.queryDictVoById({
                id: val
            }).then(res => {
                if (res.msg == '操作成功') {
                    that.editDictName = res.data.dictKey ? res.data.dictKey : '';
                    that.editDictValue = res.data.dictValue ? res.data.dictValue : '';
                    that.editDictType = res.data.dictType ? res.data.dictType : '';
                    that.editDictComment = res.data.dictComment ? res.data.dictComment : '';
                    that.dialogVisibleEdit = true;
                }
            })
        },
        deleteRoleMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteRoles({
                        roleIds: that.multipleSelection.map(item => item.roleId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                            that.multipleSelection = [];
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择角色'
                })
            }
        },
        deleteRole(val) {
            let that = this;
            this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteRoles({
                    roleIds: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        addDict() {
            let that = this;
            if (that.addDictName.length >= 2 && that.addDictName.length < 250) {
                if (that.addDictValue.length >= 2 && that.addDictValue.length < 250) {
                    if (that.addDictType != "") {
                        if (that.addDictComment.length >= 2 && that.addDictComment.length < 250) {
                            that.$apis.addDict({
                                dictKey: that.addDictName,
                                dictValue: that.addDictValue,
                                dictType: that.addDictType,
                                dictComment: that.addDictComment
                            }).then(res => {
                                if (res.msg == '操作成功') {
                                    that.updatePage();
                                    that.$message({
                                        type: 'success',
                                        message: '添加字典成功'
                                    })
                                    that.clearInput();

                                }
                            })
                        } else {
                            that.$message({
                                type: 'error',
                                message: '字典备注备注要在2-250字符之间'
                            })
                        }
                    } else {
                        that.$message({
                            type: 'error',
                            message: '未选择字典类型'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '字典值要在2-250字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '字典键要在2-250字符之间'
                })
            }

        },

        banMul() {
            if (this.multipleSelection.length > 0) {
                this.dialogVisibleMul = true;
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择用户'
                })
            }
        },

        clearInput() {
            this.addDictName = '';
            this.addDictValue = '';
            this.addDictComment = '';
            this.addDictType = '';
            this.editRoleName = '';
            this.editRoleChineseName = '';
            this.editRoleDes = '';
            this.dialogVisible = false;
            this.dialogVisibleMul = false;
            this.dialogVisibleEdit = false;
            this.editDictValue = '';
            this.editDictName = '';
            this.currentEditId = -1;
            this.currentAuthUrl = '';
            // done();
        },

        confirmBanMul() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        for (let i = 0; i < that.multipleSelection.length; i++) {
                            that.$apis.banUser({
                                availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                                userId: that.multipleSelection[i].userId,
                                denyReason: that.banReason
                            }).then(res => {
                                if (res.msg = "操作成功") {
                                    that.$message({
                                        type: 'success',
                                        message: '禁用用户(' + that.multipleSelection[i].nickname + ')成功'
                                    })
                                    if (i == that.multipleSelection.length - 1) {
                                        that.updatePage();
                                        that.clearInput();
                                        that.dialogVisibleMul = false;
                                    }
                                }
                            })
                        }

                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        confirmBan() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        that.$apis.banUser({
                            availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                            userId: that.currentBanUserId,
                            denyReason: that.banReason
                        }).then(res => {
                            if (res.msg = "操作成功") {
                                that.updatePage();
                                that.clearInput();
                                that.$message({
                                    type: 'success',
                                    message: '禁用用户成功'
                                })
                                that.dialogVisible = false;

                            }
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        ban(val) {
            this.currentBanUserId = val;
            this.dialogVisible = true;
        },
        updatePage() {
            let that = this;
            this.$apis.queryDictVos({
                keyword: this.search,
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                orderByColumn: 'update_time',
                isAsc: 'desc'
            }).then(res => {
                // console.log(res);
                that.tableData = res.rows;
                that.dataTotal = res.total;
            })
            // this.$apis.queryRoleVosNoPage({}).then(res => {
            //     if (res.msg == '操作成功') {
            //         that.allRoles = res.data;
            //     }
            // })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
        }
    },
};
</script>

<style scoped>
</style>
