import { render, staticRenderFns } from "./GoodsManage.vue?vue&type=template&id=227979e1&scoped=true"
import script from "./GoodsManage.vue?vue&type=script&lang=js"
export * from "./GoodsManage.vue?vue&type=script&lang=js"
import style0 from "./GoodsManage.vue?vue&type=style&index=0&id=227979e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227979e1",
  null
  
)

export default component.exports