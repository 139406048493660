<template>
<div>
    <div class="container">
        <el-popover placement="right" trigger="hover">
            <el-card class="box-card">
                <div slot="header" class="clearfix">

                    <el-button type="primary" @click="manageAndroidDownload">安卓/鸿蒙下载</el-button>
                    <el-button type="primary" @click="manageAndroidDownload">ios下载</el-button>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                <div class="text item">

                    <el-image style="width: 80px; height: 80px" src="/api/file/downloadImage/微信小程序二维码.jpg" :preview-src-list="['/api/file/downloadImage/微信小程序二维码.jpg']">
                    </el-image>
                </div>
                <div class="text item">
                    <!-- <el-button type="primary" @click="manageAndroidDownload">微信小程序二维码</el-button> -->

                </div>
            </el-card>
            <el-button slot="reference" type="warning">软件下载</el-button>
        </el-popover>

        <!-- <el-link href="/api/file/downloadRelease/天天旺货物管理系统.apk" target="_blank">默认链接</el-link>
        <div @click="test">test</div>
        <a href="/api/file/downloadRelease/天天旺货物管理系统.apk" target="_blank">默认链接</a> -->
    </div>
</div>
</template>

<script>
export default {
    name: 'Release',
    components: {},
    data() {
        return {};
    },
    mounted() {

    },
    methods: {

        manageAndroidDownload() {
            window.open('/api/file/downloadRelease/天天旺货物管理系统.apk')
        },
        test() {
            window.open('/api/file/downloadRelease/天天旺货物管理系统.apk')
        }
    },
};
</script>

<style scoped>
.container {
    position: fixed;
    left: 20px;
    top: 20px;
    border-radius: 10px;
    border: 0px solid gray;

    z-index: 100;
    opacity: 1;
}

.container:hover {
    opacity: 1;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.box-card {}
</style>
