import { Message } from 'element-ui';
export default {
    // 复制文本内容方法
    copyContent(text) {
        // 复制结果
        let copyResult = true
        // 创建一个input元素
        let inputDom = document.createElement('textarea');
        // 设置为只读 防止移动端手机上弹出软键盘  
        inputDom.setAttribute('readonly', 'readonly');
        // 给input元素赋值
        inputDom.value = text;
        // 将创建的input添加到body
        document.body.appendChild(inputDom);
        // 选中input元素的内容
        inputDom.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签中的内容）
        // Input要在正常的编辑状态下原生复制方法才会生效
        const result = document.execCommand('copy')
        // 判断是否复制成功
        if (result) {
            Message.success('复制成功');
            // console.log('复制成功');
        } else {
            // console.log('复制失败');
            Message.error('复制失败');
            copyResult = false
        }
        // 复制操作后再将构造的标签 移除
        document.body.removeChild(inputDom);
        // 返回复制操作的最终结果
        return copyResult;
    },
}

