<template>
<div id="app">
    <router-view></router-view>
</div>
</template>

<script>
export default {
    name: 'App',
    components: {

    },
    mounted() {
        let that = this;
        //获取现在浏览器的真实内宽和高
        let windowWidth = window.innerWidth - 20; //增加px的宽度容错，避免滚动条影响
        let windowHeight = window.innerHeight;
        that.$store.state.window.windowWidth = windowWidth;
        that.$store.state.window.windowHeight = windowHeight;
        //监听浏览器窗口变化
        window.onresize = () => {
            //获取现在浏览器的真实内宽和高
            let windowWidth = window.innerWidth - 20; //增加px的宽度容错，避免滚动条影响
            let windowHeight = window.innerHeight;
            that.$store.state.window.windowWidth = windowWidth;
            that.$store.state.window.windowHeight = windowHeight;
        }
    },

    
}
</script>

<style>
body {
    padding: 0;
    margin: 0;
}
</style>
