// import { getSession } from '@/utils';

const config = {
    baseURL: '/',
    timeout: 15000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    // 自定义全局请求头
    // headerHandlers: [
    //   () => new Promise((resolve) => {
    //     const userInfo = getSession('userInfo');
    //     resolve({'session': userInfo ? userInfo.session : ''});
    //   })
    // ],
}

export default config;
