<template>
<div>

    <el-dialog title="封禁用户" :before-close="clearInput" :visible.sync="dialogVisibleMul" width="30%">

        <div>
            <el-form ref="form" label-width="80px" size="mini">
                <el-form-item label="封禁用户">
                    <UserHeader v-for="item in multipleSelection" :userId="item.userId" style="margin-right:10px"></UserHeader>
                </el-form-item>
                <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="封禁原因">
                    <el-input v-model="banReason"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmBanMul">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="编辑角色" :before-close="clearInput" :visible.sync="dialogVisibleEdit" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                    <UserHeader :userId="currentBanUserId"></UserHeader>
                </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item> -->
                <el-form-item label="角色名">
                    <el-input placeholder="请输入角色名" v-model="editRoleName"></el-input>
                </el-form-item>
                <el-form-item label="角色中文名">
                    <el-input placeholder="请输入角色中文名" v-model="editRoleChineseName"></el-input>
                </el-form-item>
                <el-form-item label="角色描述">
                    <el-input placeholder="请输入角色描述" v-model="editRoleDes"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="confirmEdit">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="新增角色" :before-close="clearInput" :visible.sync="dialogVisible" width="30%">

        <div>
            <el-form ref="form" label-width="100px" size="mini">
                <!-- <el-form-item label="封禁用户">
                    <UserHeader :userId="currentBanUserId"></UserHeader>
                </el-form-item> -->
                <!-- <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item> -->
                <el-form-item label="角色名">
                    <el-input placeholder="请输入角色名" v-model="addRoleName"></el-input>
                </el-form-item>
                <el-form-item label="角色中文名">
                    <el-input placeholder="请输入角色中文名" v-model="addRoleChineseName"></el-input>
                </el-form-item>
                <el-form-item label="角色描述">
                    <el-input placeholder="请输入角色描述" v-model="addRoleDes"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearInput">取 消</el-button>
            <el-button type="primary" @click="addRole">确 定</el-button>
        </span>
    </el-dialog>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>

        <el-table-column label="角色编号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.roleId }}</template>
        </el-table-column>
        <el-table-column label="角色名" width="90" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.roleName }}</template>
        </el-table-column>
        <el-table-column label="角色中文名" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.roleChineseName }}</template>
        </el-table-column>
        <el-table-column label="角色备注" width="280" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.roleComment }}</template>
        </el-table-column>
        <el-table-column label="最近编辑时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateTime }}</template>
        </el-table-column>
        <el-table-column align="right" min-width="200">
            <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="请输入角色编号、角色中文名、角色名、角色备注" />
            </template>
            <!-- <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                </template> -->
        </el-table-column>
        <el-table-column align="right" width="200">
            <template slot="header" slot-scope="scope">
                <el-button size="mini" type="primary" @click="dialogVisible=true">新增角色</el-button>
                <el-button size="mini" type="danger" @click="deleteRoleMul">批量删除</el-button>
            </template>
            <template slot-scope="scope">
                <el-button size="mini" @click="edit(scope.row.roleId)">编辑</el-button>
                <el-button size="mini" type="danger" @click="deleteRole(scope.row.roleId)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- <div style="margin-top: 20px">
            <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
        </div> -->

    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5,10,15,20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
        </el-pagination>

    </div>

</div>
</template>

<script>
import UserHeader from '@/components/business/user/UserHeader.vue';
import dateUtils from '@/utils/dateUtils';
export default {
    name: 'RoleManage',
    components: {
        UserHeader
    },
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            search: '',
            currentPage: 1,
            pageSize: 10,
            dataTotal: 0,
            search: '',
            date: '',
            time: '',
            banReason: '',
            dialogVisibleEdit: false,
            dialogVisible: false,
            dialogVisibleMul: false,
            currentBanUserId: -1,
            addRoleName: '',
            addRoleChineseName: '',
            addRoleDes: '',
            editRoleName: '',
            editRoleChineseName: '',
            editRoleDes: '',
            currentEditId: -1
        };
    },
    watch: {
        search() {
            this.updatePage();
        },
        pageSize() {
            this.updatePage();
        },
        currentPage() {
            this.updatePage();
        }
    },
    mounted() {
        this.updatePage();

    },
    methods: {
        confirmEdit() {
            let that = this;
            if (that.editRoleName.length > 2 && that.editRoleName.length < 200) {
                if (that.editRoleChineseName.length > 2 && that.editRoleChineseName.length < 200) {
                    if (that.editRoleDes.length > 2 && that.editRoleDes.length < 200) {
                        that.$apis.editRoleById({
                            roleId: that.currentEditId,
                            roleName: that.editRoleName,
                            roleChineseName: that.editRoleChineseName,
                            roleComment: that.editRoleDes
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.updatePage();
                                that.$message({
                                    type: 'success',
                                    message: '编辑角色成功'
                                })
                                that.clearInput();

                            }
                        })
                    } else {
                        that.$message({
                            type: 'error',
                            message: '角色描述要在2-200字符之间'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '角色中文名要在2-200字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '角色名要在2-200字符之间'
                })
            }
        },
        edit(val) {
            this.currentEditId = val;
            let that = this;
            this.$apis.searchRoleVoById({
                roleId: val
            }).then(res => {
                if (res.msg == '操作成功') {
                    that.editRoleName = res.data.roleName ? res.data.roleName : '';
                    that.editRoleChineseName = res.data.roleChineseName ? res.data.roleChineseName : '';
                    that.editRoleDes = res.data.roleComment;
                    that.dialogVisibleEdit = true;
                }
            })
        },
        deleteRoleMul() {
            let that = this;
            if (that.multipleSelection.length > 0) {
                this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$apis.deleteRoles({
                        roleIds: that.multipleSelection.map(item => item.roleId)
                    }).then(res => {
                        if (res.msg == '操作成功') {
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                            that.multipleSelection = [];
                            that.updatePage();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择角色'
                })
            }
        },
        deleteRole(val) {
            let that = this;
            this.$confirm('此操作将删除该角色, 是否继续?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.deleteRoles({
                    roleIds: [val]
                }).then(res => {
                    if (res.msg == '操作成功') {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        addRole() {
            let that = this;
            if (that.addRoleName.length >= 2 && that.addRoleName.length < 200) {
                if (that.addRoleChineseName.length >= 2 && that.addRoleChineseName.length < 200) {
                    if (that.addRoleDes.length >= 2 && that.addRoleDes.length < 200) {
                        that.$apis.addRole({
                            roleName: that.addRoleName,
                            roleChineseName: that.addRoleChineseName,
                            roleComment: that.addRoleDes
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.updatePage();
                                that.$message({
                                    type: 'success',
                                    message: '添加角色成功'
                                })
                                that.clearInput();

                            }
                        })
                    } else {
                        that.$message({
                            type: 'error',
                            message: '角色描述要在2-200字符之间'
                        })
                    }
                } else {
                    that.$message({
                        type: 'error',
                        message: '角色中文名要在2-200字符之间'
                    })
                }
            } else {
                that.$message({
                    type: 'error',
                    message: '角色名要在2-200字符之间'
                })
            }

        },

        banMul() {
            if (this.multipleSelection.length > 0) {
                this.dialogVisibleMul = true;
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择用户'
                })
            }
        },

        clearInput() {
            this.addRoleName = '';
            this.addRoleChineseName = '';
            this.addRoleDes = '';
            this.editRoleName = '';
            this.editRoleChineseName = '';
            this.editRoleDes = '';
            this.dialogVisible = false;
            this.dialogVisibleMul = false;
            this.dialogVisibleEdit = false;
            // done();
        },

        confirmBanMul() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        for (let i = 0; i < that.multipleSelection.length; i++) {
                            that.$apis.banUser({
                                availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                                userId: that.multipleSelection[i].userId,
                                denyReason: that.banReason
                            }).then(res => {
                                if (res.msg = "操作成功") {
                                    that.$message({
                                        type: 'success',
                                        message: '禁用用户(' + that.multipleSelection[i].nickname + ')成功'
                                    })
                                    if (i == that.multipleSelection.length - 1) {
                                        that.updatePage();
                                        that.clearInput();
                                        that.dialogVisibleMul = false;
                                    }
                                }
                            })
                        }

                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        confirmBan() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        that.$apis.banUser({
                            availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                            userId: that.currentBanUserId,
                            denyReason: that.banReason
                        }).then(res => {
                            if (res.msg = "操作成功") {
                                that.updatePage();
                                that.clearInput();
                                that.$message({
                                    type: 'success',
                                    message: '禁用用户成功'
                                })
                                that.dialogVisible = false;

                            }
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        ban(val) {
            this.currentBanUserId = val;
            this.dialogVisible = true;
        },
        updatePage() {
            this.$apis.queryRoleVos({
                roleChineseName: this.search,
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                orderByColumn: 'update_time',
                isAsc: 'desc'
            }).then(res => {
                // console.log(res);
                this.tableData = res.rows;
                this.dataTotal = res.total;
            })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
        }
    },
};
</script>

<style scoped>
</style>
