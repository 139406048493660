<template>
<div class="container" :style="'width: '+width+'px; height: '+height+'px'">
    <el-image v-if="url" fit="cover" :style="'width: '+width+'px; height: '+height+'px'" :src="'/api/file/downloadImage/'+url" :preview-src-list="srcList">
        <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
        </div>
        <!-- <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
        </div> -->
    </el-image>
</div>
</template>

<script>
export default {
    name: 'QuickImage',
    components: {},
    watch: {
        url() {
            this.updatePage();
        }
    },
    props: {
        url: {
            require: true
        },
        size: {
            require: false,
            default: 'medium'
        },
        width: {
            require: false,
            default: 100
        },
        height: {
            require: false,
            default: 100
        },
        preSrcList: {
            require: false
        }
    },
    data() {
        return {
            srcList: []
        };
    },
    mounted() {
        this.updatePage();
    },
    methods: {
        updatePage() {
            let preSrcList = this.preSrcList;
            if (preSrcList == undefined || preSrcList == null || preSrcList == "" || preSrcList == []) {
                this.srcList = [this.url]
            } else {
                this.srcList = this.preSrcList
            }
            for (let i = 0; i < this.srcList.length; i++) {
                this.srcList[i] = '/api/file/downloadImage/' + this.srcList[i];
            }
        }
    },
};
</script>

<style scoped>
* {
    /**border: 0;*/
    margin: 0;
    padding: 0;
}

.container {
    display: inline-block;
}
</style>
