import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import store from '@/store'
import VueRouter from 'vue-router'
import router from '@/router'
import qs from 'qs';
import flattenObject from '@/utils/flattenObjectUtils'
import tools from './utils/tools';
//引入apis
import apis from '@/request/http'
//引入echarts
import * as echarts from "echarts";
import dateUtils from '@/utils/dateUtils'
import print from 'print-js'
Vue.prototype.$apis = apis;
Vue.config.productionTip = false
Vue.prototype.$qs = qs;
//方便直接扁平化对象
Vue.prototype.$flattenObject = flattenObject;
//注入工具类
Vue.prototype.$tools = tools;
//注入echarts
Vue.prototype.$echarts = echarts
//注入dateUtils工具
Vue.prototype.$dateUtils = dateUtils
//注入打印机
Vue.prototype.$print = print; 

Vue.use(VueRouter)
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
