<template>
<div :style="'height:'+$store.state.window.windowHeight+'px;'+'width:'+$store.state.window.windowWidth+'px;'" ref="container" class="container">
    <Menu style="position:fixed;top:0;left:0;" class="menu" :style="'width:'+$store.state.menu.width+'px;'"></Menu>
    <div style="position:absolute;top:0;right:0;" class="content" :style="'height:'+$store.state.window.windowHeight+'px;'+'width:'+($store.state.window.windowWidth-$store.state.menu.width)+'px;'">
        <div class="topMenu" style="z-index:100;position:fixed;top:0;right:0;" :style="'height:'+$store.state.contentTop.height+'px;'+'width:'+($store.state.window.windowWidth-$store.state.menu.width)+'px;'">
            <div class="block" style="float: right;">
                <el-dropdown style="margin-top: 5px;">
                    <el-avatar style="margin-right: 10px;" shape="square" :size="50" :src="'/api/file/downloadImage/'+$store.state.user.headerUrl">
                    </el-avatar>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item disabled style="color: #F56C6C;">{{ this.$store.state.user.nickname }}</el-dropdown-item>
                        <el-dropdown-item divided @click.native="go('userInfo')">个人信息</el-dropdown-item>
                        <el-dropdown-item @click.native="openPasswordDialog">修改密码</el-dropdown-item>
                        <el-dropdown-item @click.native="handlerScreenFull">全屏切换</el-dropdown-item>
                        <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>

                        <!-- <el-dropdown-item>双皮奶</el-dropdown-item>
                    <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <el-page-header @back="goBack" style="margin-top: 15px;float:left" :content="$store.state.title">
            </el-page-header>

        </div>
        <!-- 顶部menu的背景 -->
        <div class="topMenuBackground" style="z-index:0;background-color:white;position:fixed;top:0;right:0;" :style="'height:'+$store.state.contentTop.height+'px;'+'width:'+($store.state.window.windowWidth-$store.state.menu.width)+'px;'"></div>
        <!-- 由于顶部菜单固定了，原来的位置空了，使用用这个div占位置使用 -->
        <div :style="'height:'+$store.state.contentTop.height+'px;'+'width:'+($store.state.window.windowWidth-$store.state.menu.width)+'px;'" style=""></div>
        <div class="bottomContent" style="" :style="'height:'+($store.state.window.windowHeight-$store.state.contentTop.height)+'px;'+'width:'+($store.state.window.windowWidth-$store.state.menu.width)+'px;'">
            <router-view></router-view>
        </div>
    </div>
    <!-- 额外功能添加 -->
    <div>
        <!-- 点击按钮打开密码修改弹框 -->
        <el-button type="primary" @click="openPasswordDialog">修改密码</el-button>

        <!-- 密码修改弹框 -->
        <el-dialog title="修改密码" :visible.sync="passwordDialogVisible">
            <el-form ref="editPassword" :model="passwordForm" :rules="passwordRules">
                <el-form-item label="原始密码" prop="oldPassword">
                    <el-input type="password" v-model="passwordForm.oldPassword"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input type="password" v-model="passwordForm.newPassword"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input type="password" v-model="passwordForm.confirmPassword"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="passwordDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="saveNewPassword">保存</el-button>
            </span>
        </el-dialog>
    </div>
</div>
</template>

<script>
import screenFull from 'screenfull';
import Menu from '@/components/system/Menu.vue'
export default {
    name: 'Manage',
    components: {
        Menu
    },
    data() {
        return {
            screenState: false, // 屏幕的状态
            passwordDialogVisible: false,
            passwordForm: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            passwordRules: {
                oldPassword: [{
                        required: true,
                        message: '请输入原始密码',
                        trigger: 'blur'
                    }
                    // Add other validation rules as needed
                ],
                newPassword: [{
                        required: true,
                        message: '请输入新密码',
                        trigger: 'blur'
                    },
                    {
                        min: 6,
                        message: '密码长度不能少于6位',
                        trigger: 'blur'
                    }
                    // Add other validation rules as needed
                ],
                confirmPassword: [{
                        required: true,
                        message: '请确认密码',
                        trigger: 'blur'
                    },
                    {
                        validator: this.validateConfirmPassword,
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    mounted() {},
    methods: {
        openPasswordDialog() {
            this.passwordDialogVisible = true;
        },
        saveNewPassword() {
            // 校验通过后，将新密码保存到数据库
            // 这里可以添加保存逻辑
            let that = this;
            that.$refs.editPassword.validate((valid) => {
                if (valid) {
                    that.$apis.editManageUserPassword({
                        newUserPassword: that.passwordForm.newPassword,
                        userPassword: that.passwordForm.oldPassword
                    }).then(res => {
                        if (res.code == 200) {
                            that.passwordDialogVisible = false;

                            that.$message({
                                type: 'success',
                                message: '密码修改成功,请重新登录'
                            })
                            that.$router.push({
                                name: 'login'
                            })
                        }
                    })

                } else {

                }
            });

        },
        validateConfirmPassword(rule, value, callback) {
            if (value !== this.passwordForm.newPassword) {
                callback(new Error('两次输入的密码不一致'));
            } else {
                callback();
            }
        },

        go(content) {
            this.$router.push({
                name: content
            })
        },
        exit() {
            this.$apis.logout({

            }).then(res => {
                if (res.msg == '操作成功') {
                    this.$message({
                        type: 'success',
                        message: '已退出登录'
                    })
                    this.$store.dispatch("clearUser");
                    this.$router.go({
                        name: 'login'
                    });
                }
            })

        },
        goBack() {
            this.$router.back();
        },
        handlerScreenFull() {
            if (screenFull.isEnabled) { // 加个判断浏览器是否支持全屏    
                screenFull.toggle(); // 切换全屏状态  
            } else {
                this.$message.error('您的浏览器不支持全屏');
            }
        }
    }
};
</script>

<style scoped>
.container {
    border: 0px black solid;
}

.content {
    border: 0px solid black;

}

.topMenuBackground {
    opacity: 0.5;
}

.topMenu:hover~.topMenuBackground {
    opacity: 1;
}
</style>
