<template>
<div>

    <!-- 用户信息编辑表单 -->
    <el-form :model="user">

        <el-form-item label="昵称">
            <el-input v-model="user.nickname" style="display: inline-block;width:500px;"></el-input>
        </el-form-item>
        <el-form-item label="性别">
            <el-radio-group v-model="user.gender">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="用户名">
            <el-input style="display: inline-block;width:500px;" v-model="user.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="出生日">
            <el-date-picker v-model="user.dayOfBirth" type="date"></el-date-picker>
        </el-form-item>

        <el-form-item label="电话号码">
            <el-input v-model="user.phone" style="display: inline-block;width:500px"></el-input>
        </el-form-item>
        <el-form-item label="用户头像">
            <QuickUploadImage @getUrl="changeUrl">

            </QuickUploadImage>
        </el-form-item>

        <div style="text-align: center;">
            <el-button type="primary" @click="saveUserChanges">保存</el-button>

        </div>
    </el-form>
</div>
</template>

<script>
import QuickImage from './../../../components/image/QuickImage.vue'
import QuickUploadImage from './../../../components/image/QuickUploadImage.vue'
export default {
    components: {
        QuickImage,
        QuickUploadImage
    },
    data() {
        return {

            user: {
                dayOfBirth: '',
                gender: '',
                headerUrl: 'userHeader.png',
                nickname: '',
                phone: '',
                username: ''
            }

        };
    },
    methods: {
        changeUrl(content) {

            this.user.headerUrl = content;
        },
        saveUserChanges() {
            let that = this;
            that.$apis.editManageUser({
                ...that.user,
                dayOfBirth: that.$dateUtils.getDateTime(that.user.dayOfBirth)
            }).then(res => {

                if (res.code == 200) {
                    that.$router.push({
                        name: 'login'
                    })
                    that.$message({
                        type: 'success',
                        message: '修改成功，请重新登录'
                    })
                }
            })
        },
        updatePage() {
            let that = this;
            that.user = {
                ...that.$store.state.user,
                dayOfBirth: that.$dateUtils.getStandardDate(that.$store.state.user.dayOfBirth)
            }
        }
    },
    mounted() {
        this.updatePage();
    }
};
</script>

<style>

</style>
