export default [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
        name: "deleteAuths",
        path: '/api/auth/deleteAuths',
        desc: "删除权限",
    },
    {
        name: "addAuth",
        path: '/api/auth/addAuth',
        desc: "增加权限",
    },
    {
        name: "queryAuthById",
        path: '/api/auth/queryAuthById',
        desc: "查询权限通过id",
    },
    {
        name: "editAuth",
        path: '/api/auth/editAuth',
        desc: "编辑权限",
    },
    {
        name: "queryAuthVos",
        path: '/api/auth/queryAuthVos',
        desc: "查询全部权限(通过权限名)",
    }
]