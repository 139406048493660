<template>
<div class="container" :style="'width: '+width+'px; height: '+height+'px'">

    <el-upload :style="'width: '+width+'px; height: '+height+'px'" :on-remove="onRemove" :on-exceed="onExceed" :limit="1" list-type='picture' :drag="true" accept="jpg" :file-list="fileList" :on-change="onChange" ref="upload" class="avatar-uploader" :auto-upload="true" name="file" action="/api/file/uploadImageForManage" :show-file-list="true" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <el-image fit="cover " :style="'width: '+width+'px; height: '+height+'px'" v-if="imageUrl" :src="imageUrl" class="avatar"></el-image>
        <i class="el-icon-plus avatar-uploader-icon" style="border: 0px dotted gray;border-radius:10px"></i>
    </el-upload>

</div>
</template>

<script>
export default {
    name: 'QuickUploadImage',
    components: {},
    watch: {
        url() {
            this.updatePage();
        }
    },
    props: {

        width: {
            require: false,
            default: 360
        },
        height: {
            require: false,
            default: 180
        }
    },
    data() {
        return {
            imageUrl: '',
            fileList: []
        };
    },
    mounted() {
        this.updatePage();
    },
    methods: {
        updatePage() {

        },
        //上传图片代码
        onRemove(files, fileList) {
            this.fileList = fileList;
        },
        checkIsUploadHeader(rule, value, callback) {

            if (this.fileList.length == 1) {
                callback();
            } else {
                callback(new Error('未上传图片'));
            }
        },
        onExceed(files, fileList) {
            this.$message({
                message: '最多选择上传一个图片',
                type: 'error'
            })
        },
        onChange(file, fileList) {
            let that = this;

            if (file.response != undefined && file.response != null) {
                //已完成上传
                if (file.response.code = 200) {
                    that.$emit("getUrl", file.response.msg);
                    that.$message({
                        type: 'success',
                        message: '图片上传成功'

                    })
                    that.updatePage();

                } else {
                    that.$message({
                        type: 'error',
                        message: file.response.msg
                    })
                }
                that.fileList = [];
                // that.imageUrl = "";

            } else {
                //未上传，仅修改需要上传的内容
                that.fileList = fileList;
                // that.$refs.form.validate((res) => {});
                that.imageUrl = URL.createObjectURL(file.raw);
            }
        },
        handleAvatarSuccess(res, file) {
            // this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isPicture = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png');
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isPicture) {
                this.$message.error('请上传 jpg、jpeg、png 格式的图片!');
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
            }
            return isPicture && isLt10M;
        },
    },
};
</script>

<style scoped>
* {
    /**border: 0;*/
    margin: 0 !important;
    padding: 0 !important;
}

.container {
    display: inline-block;
}

/**上传图片样式*/
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
