<template>
<div>
    <section>
        <!-- 背景颜色 -->
        <div class="color"></div>
        <div class="color"></div>
        <div class="color"></div>
        <div class="box">
            <!-- 背景圆 -->
            <div class="circle" style="--x:0"></div>
            <div class="circle" style="--x:1"></div>
            <div class="circle" style="--x:2"></div>
            <div class="circle" style="--x:3"></div>
            <div class="circle" style="--x:4"></div>
            <!-- 登录框 -->
            <div class="container">
                <div class="form" style="text-align: center;padding:30px 0 30px 0;">
                    <!-- <h2>注册</h2>
                    <form method="post" action="/lin/login">
                        <div class="inputBox">
                            <input type="text" placeholder="账号" name="username">

                        </div>
                        <div class="inputBox">
                            <input type="password" placeholder="密码" name="password">

                        </div>
                        <div class="tip" @click="toTip">点击登录</div>
                        <div class="inputBox">
                            <input type="submit" value="注册">

                        </div>

                    </form> -->

                    <el-form ref="form" style="width: 400px;" :rules="rules" :model="sizeForm" label-width="80px" size="mini">
                        <el-form-item prop="username" label="用户名">
                            <el-input v-model="sizeForm.username"></el-input>
                        </el-form-item>
                        <el-form-item prop="password" label="密码">
                            <el-input type="password" v-model="sizeForm.password"></el-input>
                        </el-form-item>
                        <el-form-item prop="confirmPassword" label="确认密码">
                            <el-input type="password" v-model="sizeForm.confirmPassword"></el-input>
                        </el-form-item>
                        <el-form-item prop="nickname" label="昵称">
                            <el-input v-model="sizeForm.nickname"></el-input>
                        </el-form-item>
                        <el-form-item label="出生时间" prop="dayOfBirth">
                            <el-col :span="11">
                                <el-date-picker :picker-options="expireTimeOPtion" type="date" placeholder="选择日期" v-model="sizeForm.dayOfBirth" style="width: 100%;"></el-date-picker>
                            </el-col>
                        </el-form-item>
                        <el-form-item prop="gender" label="性别">
                            <el-radio-group v-model="sizeForm.gender" size="medium">
                                <el-radio style="border: 0;" border label="男"></el-radio>
                                <el-radio style="border: 0;" border label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item prop="phone" label="手机号">
                            <el-input v-model="sizeForm.phone"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="活动区域">
                            <el-select v-model="sizeForm.region" placeholder="请选择活动区域">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item> -->

                        <!-- <el-form-item label="活动性质">
                            <el-checkbox-group v-model="sizeForm.type">
                                <el-checkbox-button label="美食/餐厅线上活动" name="type"></el-checkbox-button>
                                <el-checkbox-button label="地推活动" name="type"></el-checkbox-button>
                                <el-checkbox-button label="线下主题活动" name="type"></el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item> -->

                        <!-- <el-button>取消</el-button> -->
                        <el-form-item prop="imageUrl" label="上传头像">
                            <el-upload :on-remove="onRemove" :on-exceed="onExceed" :limit="1" list-type='picture' :drag="true" accept="jpg" :file-list="fileList" :on-change="onChange" :data="{username:sizeForm.username,userPassword:sizeForm.password,phone:sizeForm.phone,nickname:sizeForm.nickname,gender:sizeForm.gender,dayOfBirth:getDateTime(sizeForm.dayOfBirth)}" ref="upload" class="avatar-uploader" :auto-upload="false" name="file" action="/api/user/commonRegister" :show-file-list="true" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <!-- <img v-if="sizeForm.imageUrl" :src="sizeForm.imageUrl" class="avatar"> -->
                                <i class="el-icon-plus avatar-uploader-icon" style="border: 0px dotted gray;border-radius:10px"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <!-- <el-button style="margin-top: 20px;" type="primary" @click="onSubmit">立即注册</el-button> -->
                    <div class="tip" @click="toTip">已有账号？点击登录</div>
                    <div class="inputBox">
                        <input type="submit" @click.prevent="onSubmit" value="注册">

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import dateUtils from '@/utils/dateUtils';
export default {
    name: 'Register',
    components: {},
    data() {
        return {

            fileList: [],
            expireTimeOPtion: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的 
                }
            },
            sizeForm: {
                username: '',
                password: '',
                confirmPassword: '',
                nickname: '',
                gender: '',
                dayOfBirth: '',
                phone: '',
                imageUrl: ''
            },
            rules: {
                username: [{
                    required: true,
                    message: '用户名不能为空',
                    trigger: ['blur', 'change']
                }, {
                    pattern: /^[1-9]\d*$/,
                    message: '用户名只能为数字',
                    trigger: ['blur', 'change']
                }, {
                    min: 8,
                    max: 20,
                    message: '用户名只能在8-20个字符之间',
                    trigger: ['blur', 'change']
                }],
                password: [{
                        required: true,
                        message: '密码不能为空',
                        trigger: ['blur', 'change']
                    },
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){0,100}$/,
                        message: '英文字母、数字或标点符号至少包含两种',
                        trigger: ['blur', 'change']
                    },
                    {
                        min: 8,
                        max: 20,
                        message: '密码只能在8-20个字符之间',
                        trigger: ['blur', 'change']
                    }
                ],
                confirmPassword: [{
                    validator: this.validatePassword,
                    trigger: ['blur', 'change']
                }, {
                    required: true,
                    message: '密码不能为空',
                    trigger: ['blur', 'change']
                }, {
                    pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){0,100}$/,
                    message: '英文字母、数字或标点符号至少包含两种',
                    trigger: ['blur', 'change']
                }, {
                    min: 8,
                    max: 20,
                    message: '密码只能在8-20个字符之间',
                    trigger: ['blur', 'change']
                }],
                nickname: [{
                    required: true,
                    message: '昵称不能为空',
                    trigger: ['blur', 'change']
                }, {
                    pattern: /^[\u4e00-\u9fa5]+$/,
                    message: '请输入中文',
                    trigger: ['blur', 'change']
                }, {
                    min: 2,
                    max: 8,
                    message: '昵称只能在2-8个字符之间',
                    trigger: ['blur', 'change']
                }],
                dayOfBirth: [{
                    required: true,
                    message: '未选择出生日期',
                    trigger: ['blur', 'change']
                }],
                gender: [{
                    required: true,
                    message: '未选择性别',
                    trigger: ['blur', 'change']
                }],
                phone: [{
                    required: true,
                    message: '手机号不能为空',
                    trigger: ['blur', 'change']
                }, {
                    validator: this.phoneNumberValider,
                    trigger: ['blur', 'change']
                }],
                imageUrl: [{
                    validator: this.checkIsUploadHeader,
                    trigger: ['blur', 'change']
                }]
            },

        };
    },
    mounted() {},
    methods: {
        onRemove(files, fileList) {
            this.fileList = fileList;
            this.$refs.form.validate((res) => {});
        },
        checkIsUploadHeader(rule, value, callback) {

            if (this.fileList.length == 1) {
                callback();
            } else {
                callback(new Error('未上传头像'));
            }
        },
        onExceed(files, fileList) {
            this.$message({
                message: '最多选择上传一个头像',
                type: 'error'
            })
        },
        onChange(file, fileList) {

            if (file.response != undefined && file.response != null) {
                if (file.response.msg == '操作成功') {
                    this.$message({
                        type: 'success',
                        message: '注册成功'
                    })
                    this.$router.push({
                        name: 'login'
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: file.response.msg
                    })
                }
            }
            this.fileList = fileList;
            this.$refs.form.validate((res) => {});
        },
        getDateTime(val) {
            return dateUtils.getDateTime(val);
        },
        handleAvatarSuccess(res, file) {
            this.sizeForm.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isPicture = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png');
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isPicture) {
                this.$message.error('请上传 jpg、jpeg、png 格式的图片!');
            }
            if (!isLt5M) {
                this.$message.error('上传头像图片大小不能超过 5MB!');
            }
            return isPicture && isLt5M;
        },
        phoneNumberValider(rule, value, callback) {
            if (value != "") {
                let reg11 = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (reg11.test(value)) {
                    callback()
                } else {
                    callback(new Error("请输入正确格式手机号！"))
                }
            } else {
                callback(new Error("请输入手机号"))
            }
        },
        validatePassword(rule, value, callback) {
            if (this.sizeForm.password !== this.sizeForm.confirmPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        },
        toTip() {
            this.$router.push({
                name: 'login'
            })
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$refs.upload.submit();
                }
            })
        }
    },
};
</script>

<style scoped>
.tip {
    margin-top: 20px;
    text-align: right;
    color: #909399;
}

/* 清除浏览器默认边距，
使边框和内边距的值包含在元素的width和height内 */

/* 使用flex布局，让内容垂直和水平居中 */

section {
    /* 相对定位 */
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* linear-gradient() 函数用于创建一个表示两种或多种颜色线性渐变的图片 */
    background: linear-gradient(to bottom, #f1f4f9, #dff1ff);
}

/* 背景颜色 */

section .color {
    /* 绝对定位 */
    position: absolute;
    /* 使用filter(滤镜) 属性，给图像设置高斯模糊*/
    filter: blur(200px);
}

/* :nth-child(n) 选择器匹配父元素中的第 n 个子元素 */

section .color:nth-child(1) {
    top: -350px;
    width: 600px;
    height: 600px;
    background: #ff359b;
}

section .color:nth-child(2) {
    bottom: -150px;
    left: 100px;
    width: 500px;
    height: 500px;
    background: #fffd87;
}

section .color:nth-child(3) {
    bottom: 50px;
    right: 100px;
    width: 500px;
    height: 500px;
    background: #00d2ff;
}

.box {
    position: relative;
}

/* 背景圆样式 */

.box .circle {
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    /* backdrop-filter属性为一个元素后面区域添加模糊效果 */
    backdrop-filter: blur(5px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    /* 使用filter(滤镜) 属性，改变颜色。
hue-rotate(deg)  给图像应用色相旋转 
calc() 函数用于动态计算长度值 
var() 函数调用自定义的CSS属性值x*/
    filter: hue-rotate(calc(var(--x) * 70deg));
    /* 调用动画animate，需要10s完成动画，
linear表示动画从头到尾的速度是相同的，
infinite指定动画应该循环播放无限次*/
    animation: animate 10s linear infinite;
    /* 动态计算动画延迟几秒播放 */
    animation-delay: calc(var(--x) * -1s);
}

/* 背景圆动画 */

@keyframes animate {

    0% {

        transform: translateX(-500px);

    }

    100% {
        transform: translateY(-500px);

    }

    75% {
        transform: translateX(500px);

    }

    50% {
        transform: translateY(500px);
    }
}

.box .circle:nth-child(1) {
    top: -50px;
    right: -60px;
    width: 100px;
    height: 100px;
}

.box .circle:nth-child(2) {
    top: 150px;
    left: -100px;
    width: 120px;
    height: 120px;
    z-index: 2;
}

.box .circle:nth-child(3) {
    bottom: 50px;
    right: -60px;
    width: 80px;
    height: 80px;
    z-index: 2;
}

.box .circle:nth-child(4) {
    bottom: -80px;
    left: 100px;
    width: 60px;
    height: 60px;
}

.box .circle:nth-child(5) {
    top: -80px;
    left: 140px;
    width: 60px;
    height: 60px;
}

/* 登录框样式 */

.container {
    text-align: left;
    position: relative;
    width: 800px;
    min-height: 600px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 1em;
}

.form {}

/* 登录标题样式 */

.form h2 {
    position: relative;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 5px;
    margin-bottom: 30px;
    cursor: pointer;
}

/* 登录标题的下划线样式 */

.form h2::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 0px;
    height: 3px;
    background: #fff;
    transition: 0.5s;
}

.form h2:hover:before {
    width: 53px;
}

.form .inputBox {
    width: 100%;
    margin-top: 20px;
}

/* 输入框样式 */

.form .inputBox input {
    width: 100%;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    border: none;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.form .inputBox input::placeholder {
    color: #fff;
}

/* 登录按钮样式 */

.form .inputBox input[type="submit"] {
    background: #F56C6C;
    color: white;
    max-width: 200px;
    margin-bottom: 20px;
    font-size: 1.1em;
    font-weight: 600;
    cursor: pointer;
    /* opacity: 0.95; */
}

.forget {
    margin-top: 6px;
    color: #fff;
    letter-spacing: 1px;
}

.forget a {
    color: #fff;
    /*font-weight: 500;*/
    text-decoration: none;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
