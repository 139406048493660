import VueRouter from "vue-router";
import Login from '@/pages/User/Login'
import Register from '@/pages/User/Register'
import Manage from '@/pages/Manage/Manage'
import GoodsManage from '@/pages/Manage/ManageItem/GoodsManage'
import ManageIndex from '@/pages/Manage/ManageItem/ManageIndex'
import UserManage from '@/pages/Manage/ManageItem/UserManage'
import RoleManage from '@/pages/Manage/ManageItem/RoleManage'
import AuthManage from '@/pages/Manage/ManageItem/AuthManage'
import DictManage from '@/pages/Manage/ManageItem/DictManage'
import GoodsCategoryManage from '@/pages/Manage/ManageItem/GoodsCategoryManage'
import TestManage from "@/pages/Manage/ManageItem/TestManage.vue";
import SystemConfigurationManage from "@/pages/Manage/ManageItem/SystemConfigurationManage.vue";
import WaitDeliverOrderManage from '@/pages/Manage/ManageItem/WaitDeliverOrderManage'
import DeliveringOrderManage from "@/pages/Manage/ManageItem/DeliveringOrderManage.vue";
import WaitGetMoneyOrderManage from "@/pages/Manage/ManageItem/WaitGetMoneyOrderManage.vue";
import GetedMoneyOrderManage from "@/pages/Manage/ManageItem/GetedMoneyOrderManage.vue";
import CanceledOrderManage from "@/pages/Manage/ManageItem/CanceledOrderManage.vue";
import UserInfo from './../pages/Manage/User/UserInfo.vue'

import apis from "@/request/http";
import store from "@/store";
import { Message } from 'element-ui';

let router = new VueRouter({

    routes: [
        {
            //一级路由path前加/
            path: '/',
            component: Login,
            name: 'login',
            meta: {
                title: '登录'
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                title: '注册'
            }
        },
        {
            name: 'manage',
            path: '/manage',
            component: Manage,
            meta: {
                title: '管理'
            },
            children: [
                {
                    name: 'manageIndex',
                    path: 'manageIndex',
                    component: ManageIndex,
                    meta: {
                        title: '后台管理首页'
                    }
                },

                {
                    name: 'userManage',
                    path: 'userManage',
                    component: UserManage,
                    meta: {
                        title: '用户管理'
                    }
                },
                {
                    name: 'roleManage',
                    path: 'roleManage',
                    component: RoleManage,
                    meta: {
                        title: '角色管理'
                    }
                },
                {
                    name: 'authManage',
                    path: 'authManage',
                    component: AuthManage,
                    meta: {
                        title: '权限管理'
                    }
                },
                {
                    name: 'dictManage',
                    path: 'dictManage',
                    component: DictManage,
                    meta: {
                        title: '字典管理'
                    }
                },
                {
                    name: 'goodsManage',
                    path: 'goodsManage',
                    component: GoodsManage,
                    meta: {
                        title: '商品管理'
                    }
                },
                {
                    name: 'goodsCategoryManage',
                    path: 'goodsCategoryManage',
                    component: GoodsCategoryManage,
                    meta: {
                        title: '商品类别管理'
                    }
                },
                {
                    name: 'testManage',
                    path: 'testManage',
                    component: TestManage,
                    meta: {
                        title: '测试管理'
                    }
                },
                {
                    name: 'systemConfigurationManage',
                    path: 'systemConfigurationManage',
                    component: SystemConfigurationManage,
                    meta: {
                        title: '系统配置管理'
                    }
                },
                {
                    name: 'waitDeliverOrderManage',
                    path: 'waitDeliverOrderManage',
                    component: WaitDeliverOrderManage,
                    meta: {
                        title: '待发货订单'
                    }
                },
                {
                    name: 'deliveringOrderManage',
                    path: 'deliveringOrderManage',
                    component: DeliveringOrderManage,
                    meta: {
                        title: '派送中订单'
                    }
                },
                {
                    name: 'waitGetMoneyOrderManage',
                    path: 'waitGetMoneyOrderManage',
                    component: WaitGetMoneyOrderManage,
                    meta: {
                        title: '待结款订单'
                    }
                },
                {
                    name: 'getedMoneyOrderManage',
                    path: 'getedMoneyOrderManage',
                    component: GetedMoneyOrderManage,
                    meta: {
                        title: '已结款订单'
                    }
                },
                {
                    name: 'canceledOrderManage',
                    path: 'canceledOrderManage',
                    component: CanceledOrderManage,
                    meta: {
                        title: '已取消订单'
                    }
                },
                {
                    name: 'userInfo',
                    path: 'userInfo',
                    component: UserInfo,
                    meta: {
                        title: '用户个人信息'
                    }
                }
            ]
        }
    ]
})
//检测是否登录
setInterval(() => {
    // console.log('检测是否登录')
    let cuurentRouteName = router.currentRoute.name;
    if (cuurentRouteName != 'login' && cuurentRouteName != 'register') {
        apis.checkIsLogin({}).then(res => {
            let isLogin = res.data;
            if (!isLogin) {
                router.push({
                    name: 'login'
                })
                Message.error('请重新登录');
            }
        })
    }

}, 50000)

router.beforeEach((to, from, next) => {
    // console.log(to);
    //设置标题
    if (to.meta.title != null && to.meta.title != undefined) {
        store.dispatch('setTitle', to.meta.title);
    }
    if (to.name != 'login' && to.name != 'register' && store.state.user.userId == -1) {
        router.push({
            name: 'login'
        })
    }
    next();
})

export default router;