import Vue from 'vue'
import Vuex from "vuex"
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)


const mutations = {
    IDJIA(state, value) {

    }
}




export default new Vuex.Store({
    state: {
        title: '',
        user: {
            availableTime: '',
            createBy: "",
            createTime: "",
            dayOfBirth: '',
            delFlag: 0,
            denyReason: '',
            failCount: 0,
            gender: '',
            headerUrl: 'userHeader.png',
            nickname: '未登录',
            phone: '',
            // token: '',
            updateBy: '',
            updateTime: '',
            userId: -1,
            username: '未登录',
            saToken: 'saToken'
        },
        window: {
            windowWidth: 0,
            windowHeight: 0
        },
        menu: {
            width: 250 //menu宽度
        },
        contentTop: {
            height: 60//内容顶部区域高度
        },
        screenState: false // 屏幕的状态
    },
    actions: {
        //更新用户信息
        updateUser(context, value) {
            context.commit('UPDATEUSER', value)
        },
        //清除用户消息
        clearUser(context, value) {
            context.commit('CLEARUSER', value)
        },
        //设置标题
        setTitle(context, value) {
            context.commit('SETTITLE', value)
        },
    },
    mutations: {
        //更新用户信息
        UPDATEUSER(state, value) {
            state.user = value
        },
        //清除用户消息
        CLEARUSER(state, value) {
            state.user = {
                availableTime: '',
                createBy: "",
                createTime: "",
                dayOfBirth: '',
                delFlag: 0,
                denyReason: '',
                failCount: 0,
                gender: '',
                headerUrl: 'userHeader.png',
                nickname: '未登录',
                phone: '',
                token: '',
                updateBy: '',
                updateTime: '',
                userId: -1,
                username: '未登录'
            }
        },
        //设置标题
        SETTITLE(state, value) {
            state.title = value
        },

    },
    plugins: [createPersistedState()]
})