<template>
<div>

    <el-menu :unique-opened="false" @select="go" :style="'height:'+$store.state.window.windowHeight+'px;'" :default-active="$route.name" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="manageIndex">
            <i class="el-icon-s-home"></i>
            <span slot="title">后台管理首页</span>
        </el-menu-item>
        <el-submenu index="1" v-if="isAdmin">
            <template slot="title">
                <i class="el-icon-menu"></i>
                <span>账号管理</span>
            </template>
            <el-menu-item-group title="用户角色管理">
                <el-menu-item index="userManage">
                    <i class="el-icon-user-solid"></i>
                    <span>用户管理</span>
                </el-menu-item>
                <el-menu-item index="roleManage">
                    <i class="el-icon-s-custom"></i>
                    <span>角色管理</span>
                </el-menu-item>
            </el-menu-item-group>

            <el-menu-item-group title="权限管理">
                <el-menu-item index="authManage">
                    <i class="el-icon-lock"></i>
                    <span>权限管理</span>
                </el-menu-item>
            </el-menu-item-group>

            <!-- <el-submenu index="1-4">
                <template slot="title">权限管理</template>
                <el-menu-item index="1-4-1">选项1</el-menu-item>
            </el-submenu> -->
        </el-submenu>
        <el-submenu index="2" v-if="isAdmin">
            <template slot="title">
                <i class="el-icon-reading"></i>
                <span>字典管理</span>
            </template>
            <el-menu-item index="dictManage">
                <i class="el-icon-notebook-1"></i>
                <span>字典管理</span>
            </el-menu-item>
        </el-submenu>

        <!-- <el-submenu index="3" v-if="isBusiness">
            <template slot="title">
                <i class="el-icon-truck"></i>
                <span>货物管理</span>
            </template>
            <el-menu-item index="goodsManage">
                <i class="el-icon-s-shop"></i>
                <span>商品管理</span>
            </el-menu-item>
            <el-menu-item index="goodsCategoryManage">
                <i class="el-icon-document-copy"></i>
                <span>商品类别管理</span>
            </el-menu-item>

        </el-submenu> -->
        <el-menu-item index="goodsManage">
            <i class="el-icon-s-shop"></i>
            <span>商品管理</span>
        </el-menu-item>
        <el-menu-item index="goodsCategoryManage">
            <i class="el-icon-document-copy"></i>
            <span>商品类别管理</span>
        </el-menu-item>

        <!-- <el-submenu index="4" v-if="isBusiness">
            <template slot="title">
                <i class="el-icon-s-order"></i>
                <span>订单</span>
            </template>

            <el-menu-item index="waitDeliverOrderManage">
                <i class="el-icon-tickets"></i>
                <span>待发货订单</span>
            </el-menu-item>
            <el-menu-item index="deliveringOrderManage">
                <i class="el-icon-bicycle"></i>
                <span>派送中订单</span>
            </el-menu-item>
            <el-menu-item index="waitGetMoneyOrderManage">
                <i class="el-icon-s-finance"></i>
                <span>待结款订单</span>
            </el-menu-item>
            <el-menu-item index="getedMoneyOrderManage">
                <i class="el-icon-s-claim"></i>
                <span>已结款订单</span>
            </el-menu-item>
        </el-submenu> -->

        <el-menu-item index="waitDeliverOrderManage">
            <i class="el-icon-tickets"></i>
            <span>待发货订单</span>
        </el-menu-item>
        <el-menu-item index="deliveringOrderManage">
            <i class="el-icon-bicycle"></i>
            <span>派送中订单</span>
        </el-menu-item>
        <el-menu-item index="waitGetMoneyOrderManage">
            <i class="el-icon-s-finance"></i>
            <span>待结款订单</span>
        </el-menu-item>
        <el-menu-item index="getedMoneyOrderManage">
            <i class="el-icon-s-claim"></i>
            <span>已结款订单</span>
        </el-menu-item>

        <el-menu-item index="canceledOrderManage">
            <i class="el-icon-circle-close"></i>
            <span>已取消订单</span>
        </el-menu-item>

        <el-submenu index="12" v-if="isBusiness">
            <template slot="title">
                <i class="el-icon-setting"></i>
                <span>系统配置</span>
            </template>

            <el-menu-item index="systemConfigurationManage">
                <i class="el-icon-s-tools"></i>
                <span>系统配置管理</span>
            </el-menu-item>
        </el-submenu>

        <el-submenu index="15" v-if="isAdmin">
            <template slot="title">
                <i class="el-icon-truck"></i>
                <span>开发功能测试</span>
            </template>

            <el-menu-item index="testManage">
                <i class="el-icon-document-copy"></i>
                <span>测试管理</span>
            </el-menu-item>
        </el-submenu>

        <!-- <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span slot="title">导航二</span>
        </el-menu-item>
        <el-menu-item index="3" disabled>
            <i class="el-icon-document"></i>
            <span slot="title">导航三</span>
        </el-menu-item>
        <el-menu-item index="aa">
            <i class="el-icon-setting"></i>
            <span slot="title">导航四</span>
        </el-menu-item> -->
    </el-menu>
</div>
</template>

<script>
export default {
    name: 'Menu',
    components: {},
    data() {
        return {
            isAdmin: false,
            isBusiness: false
        };
    },
    mounted() {
        this.updatePage();
    },
    methods: {
        updatePage() {
            let that = this;
            that.isAdmin = false;
            that.isBusiness = false;
            this.$apis.querySelfAllRoles().then(res => {
                // console.log(res);
                let data = res.data;
                data.map(item => {
                    switch (item.roleName) {
                        case 'admin':
                            that.isAdmin = true;
                            break;
                        case 'business':
                            that.isBusiness = true;
                            break;
                        default:
                            break;
                    }
                })
            })
        },
        go(name) {
            this.$router.push({
                name
            })
        },

    },
};
</script>

<style scoped>
</style>
