<template>
<div class="container">

    <!-- 资产申购单-打印预览 -->
    <div class="content-view">
        <div id="print" v-loading="loading">
            <div class="print-header">
                <div class="print-title">资产申购单123</div>
                <a-button id="btn" @click="print" class="print-btn no-print">
                    打印
                </a-button>
            </div>
            <div>
                <div>111</div>
                <div style="break-after: page"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import EditGoods from '@/components/business/goodsBusiness/goodsManage/EditGoods'
export default {
    name: 'TestManage',
    components: {
        EditGoods
    },
    data() {
        return {
            loading: false,
            isShow: false,
        };
    },
    mounted() {

    },
    methods: {
        print() {
            const style = "@page {margin:15mm 5mm};"; //打印时去掉眉页眉尾
            this.$print({
                printable: "print", // 标签元素id
                type: "html",
                headerStyle: "font-size:18px;",
                targetStyles: ["*"],
                documentTitle: "标题",
                ignoreElements: ["btn"], //需要忽略的打印元素
                margin: [0],
                style,
            });
        },
    },
};
</script>

<style scoped>
.container {
    background-color: yellow;
}
</style>
