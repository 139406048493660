<template>
<div>
    <div class="container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>销售额周数据分析</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
            </div>
            <div ref="weekAnalyse" style="width: 500px;height:500px"></div>
        </el-card>

    </div>
</div>
</template>

<script>
export default {
    name: 'ManageIndex',
    components: {},
    data() {
        return {
            value: new Date()
        };
    },
    mounted() {
        let that = this;
        that.updatePage();
    },
    methods: {
        //更新页面
        updatePage() {
            let that = this;
            that.updateTestCharts();

        },

        //更新子项

        updateTestCharts() {
            let that = this;
            let chartDom = this.$refs.weekAnalyse;
            let myChart = this.$echarts.init(chartDom);
            let option;
            that.$apis.weekAnalyse({

            }).then(res => {
                if (res.msg == "操作成功") {
                    option = {
                        title: {
                            text: '天天旺周销售额数据分析',
                            subtext: '实时数据分析',
                            left: 'center'
                        },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left'
                        },
                        series: [{
                            name: '销售额周数据分析',
                            type: 'pie',
                            radius: '50%',
                            data: res.data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }]
                    };

                    option && myChart.setOption(option);
                }
            })

        },

    },
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
}

.box-card {
    margin: 5px 10px 5px 10px;
}
</style>
