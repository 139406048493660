<template>
<div>
    <div style="width: 650px;position:fixed;top:20px;left:40%;z-index:1000;">
        <el-input class="queryInput" v-model="query.keyword" placeholder="请输入订单相关信息" style="display: inline-block;width: 300px;">

        </el-input>
        <el-button type="warning" size="small" @click="allDeliverOrderByBusiness" style="margin-left: 20px;">一键发货</el-button>
        <el-button type="danger" size="small" @click="print" style="margin-left: 20px;" v-if="system.isEasy">打印</el-button>

        <el-button type="success" size="small" @click="system.isEasy=true" style="margin-left: 20px;" v-if="!system.isEasy">精简模式</el-button>
        <el-button type="primary" size="small" @click="system.isEasy=false" style="margin-left: 20px;" v-else>全数据模式</el-button>
 
    </div>
    <el-empty v-if="query.rows.length <= 0" :image-size="300"></el-empty>
    <div id="printView">
        <div v-for="item in query.rows" style="margin-bottom: 30px;" :key="item.orderId">
            <el-descriptions class="margin-top" :column="2" size="mini" border style="border:3px 0  0  3px solid black;">
                <!-- <template slot="extra">
                <el-button type="primary" size="small">发货</el-button>
            </template> -->
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        收货人
                    </template>
                    {{ item.consignee }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        订单号
                    </template>
                    <el-tag style="font-size: 1.2em;font-weight:600" effect="dark" type="danger"> {{ item.orderId }}</el-tag>
                </el-descriptions-item>

                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        手机号
                    </template>
                    {{ item.phone }}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-thumb"></i>
                        订单操作
                    </template>
                    <el-button type="primary" size="small" @click="deliverOrderByBusiness(item.orderId)">发货</el-button>
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-location-outline"></i>
                        所在地区
                    </template>
                    {{ item.area }}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-place"></i>
                        详细地址
                    </template>
                    {{ item.detailArea }}
                    <!-- <el-tag size="small">学校</el-tag> -->
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-copy-document"></i>
                        地址操作
                    </template>
                    <el-button type="primary" size="small" @click="$tools.copyContent(item.area+' '+item.detailArea)">一键复制地址</el-button>

                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-coin"></i>
                        订单原始总价
                    </template>
                    ￥{{item.orderRawCountPrice}}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-coin"></i>
                        订单折后总价
                    </template>
                    ￥{{item.orderNowCountPrice}}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-coin"></i>
                        订单已实付金额
                    </template>
                    ￥{{item.orderActuallyPaid}}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-c-scale-to-original"></i>
                        订单状态
                    </template>
                    {{item.orderSatus}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-time"></i>
                        订单创建时间
                    </template>
                    {{item.createTime}}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        订单创建者
                    </template>
                    <UserHeader :user-id="item.createBy"></UserHeader>
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-time"></i>
                        订单更新时间
                    </template>
                    {{item.updateTime}}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-user-solid"></i>
                        订单更新者
                    </template>
                    <UserHeader :user-id="item.updateBy"></UserHeader>
                </el-descriptions-item>

                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-thumb"></i>
                        取消订单操作
                    </template>
                    <el-button type="danger" size="small" @click="cancelOrder(item.orderId)">取消订单</el-button>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions v-for="(item2,index) in item.orderGoodsSnapshootVos" :key="item2.orderGoodsSnapshootId" style="margin-top: 10px;border:3px solid #F56C6C" class="margin-top" :column="3" size="mini" border>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-s-flag"></i>
                        商品
                    </template>
                    {{ index+1 }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-soccer"></i>
                        商品数量
                    </template>

                    <el-tag style="font-size: 1.2em;font-weight:600">{{item2.goodsCount}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-help"></i>
                        商品图片
                    </template>
                    <QuickImage :url="item2.goodsImage"></QuickImage>
                </el-descriptions-item>
                
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-coin"></i>
                        商品原价
                    </template>
                    ￥{{item2.rawPrice}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-coin"></i>
                        商品折后价
                    </template>
                    ￥{{item2.actualPrice}}
                </el-descriptions-item>
               
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-s-flag"></i>
                        商品名称
                    </template>
                    {{ item2.goodsName }}
                </el-descriptions-item>
                <el-descriptions-item v-if="!system.isEasy">
                    <template slot="label">
                        <i class="el-icon-notebook-2"></i>
                        商品描述
                    </template>
                    {{item2.goodsDescribe}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-collection"></i>
                        商品单位
                    </template>
                    {{item2.goodsUnit}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-receiving"></i>
                        商品规格
                    </template>
                    {{item2.goodsSpecification}}
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1,5,10,20,100]" :page-size="pageConfiguration.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageConfiguration.dataTotal">
        </el-pagination>

    </div>
</div>
</template>

<script>
import QuickImage from '@/components/image/QuickImage'
import UserHeader from '@/components/business/user/UserHeader'
export default {
    name: 'WaitDeliverOrderManage',
    components: {
        QuickImage,
        UserHeader
    },
    watch: {
        'pageConfiguration.pageNum'() {
            this.updatePage();
        },
        'pageConfiguration.pageSize'() {
            this.updatePage();
        },
        'query.keyword'() {
            this.updatePage();
        }
    },
    data() {
        return {
            system: {
                isEasy: false
            },
            query: {
                keyword: '',
                rows: []
            },
            pageConfiguration: {
                pageSize: 1,
                dataTotal: 0,
                pageNum: 1
            }
        };
    },
    mounted() {
        this.updatePage();
        this.updateToalCount();
    },
    methods: {
        print() {
            const style = "@page {margin:15mm 5mm};"; //打印时去掉眉页眉尾
            this.$print({
                printable: "printView", // 标签元素id
                type: "html",
                headerStyle: "font-size:18px;",
                targetStyles: ["*"],
                documentTitle: "天天旺发货单",
                ignoreElements: ["btn"], //需要忽略的打印元素
                margin: [0],
                style,
            });
        },
        cancelOrder(id) {
            let that = this;

            this.$prompt('请输入取消订单原因', '取消订单', {
                confirmButtonText: '确定取消',
                cancelButtonText: '关闭',
            }).then(({
                value
            }) => {
                // this.$message({
                //     type: 'success',
                //     message: '你的邮箱是: ' + value
                // });
                that.$apis.businessCancelOrder({
                    orderId: id,
                    cancelOrderReason: value
                }).then(res => {
                    if (res.code == 200) {

                        that.updatePage();

                        that.$message({
                            type: 'success',
                            message: '取消订单成功'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消订单操作'
                });
            });
        },
        allDeliverOrderByBusiness() {
            let that = this;

            this.$confirm('此操作将发货全部订单, 是否继续?', '全部订单发货提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                that.$apis.allDeliverOrderByBusiness({

                }).then((res) => {
                    if (res.code == 200) {

                        that.updatePage();

                        that.$message({
                            type: 'success',
                            message: '发货成功'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消发货'
                });
            });
        },
        deliverOrderByBusiness(id) {
            let that = this;

            this.$confirm('此操作将发货该订单, 是否继续?', '发货提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                that.$apis.deliverOrderByBusiness({
                    id
                }).then((res) => {
                    if (res.code == 200) {

                        that.updatePage();

                        that.$message({
                            type: 'success',
                            message: '发货成功'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消发货'
                });
            });

        },
        updateToalCount() {
            let that = this;
            that.$apis.queryAllOrderByBusiness({
                isAsc: 'asc',
                orderByColumn: 'create_time',
                keyword: '',
                orderSatus: '待发货',
                pageNum: that.pageConfiguration.pageNum,
                pageSize: that.pageConfiguration.pageSize
            }).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    // that.$message({
                    //     showClose: true,
                    //     message: '还有' + res.total + '个订单待发货',
                    //     type: 'success'
                    // });
                    if (res.total > 0) {
                        that.$notify({
                            duration: 5000,
                            title: '代发货订单提示',
                            message: '还有' + res.total + '个订单待发货',
                            type: 'success'
                        });
                    }
                    // that.pageConfiguration.dataTotal = res.total;
                }
            })
        },

        updatePage() {
            let that = this;
            that.$apis.queryAllOrderByBusiness({
                isAsc: 'asc',
                orderByColumn: 'create_time',
                keyword: that.query.keyword,
                orderSatus: '待发货',
                pageNum: that.pageConfiguration.pageNum,
                pageSize: that.pageConfiguration.pageSize
            }).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    that.query.rows = res.rows;
                    that.pageConfiguration.dataTotal = res.total;
                }
            })

        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageConfiguration.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageConfiguration.pageNum = val
        }
    },
};
</script>

<style scoped>

.queryInput {
    opacity: 0.7;
}

.queryInput:hover,
.queryInput:focus {
    opacity: 1;
}
</style>
