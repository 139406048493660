<template>
<div>
    <el-descriptions class="margin-top" title="系统核心配置" :column="2" border>
        <!-- <template slot="extra">
            <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
            <template slot="label">
                <i class="el-icon-truck"></i>
                起送价
            </template>
            <el-input placeholder="请输入用户起送价" v-model="systemConfiguration.sendMinPrice" @keyup.enter.native="saveSendMinPrice" class="input-with-select">

                <el-button style="color: #67C23A;" slot="append" icon="el-icon-check" @click="saveSendMinPrice"></el-button>
            </el-input>
        </el-descriptions-item>
        <el-descriptions-item>
            <template slot="label">
                <i class="el-icon-monitor"></i>
                待开发功能
            </template>
            功能待开发
        </el-descriptions-item>
    </el-descriptions>
</div>
</template>

<script>
export default {
    name: 'SystemConfigurationManage',
    components: {},
    data() {
        return {
            systemConfiguration: {
                sendMinPrice: ''
            }
        };
    },
    mounted() {
        this.updatePage();
    },
    methods: {
        saveSendMinPrice() {
            this.$apis.saveSendMinPrice({
                sendMinPrice: this.systemConfiguration.sendMinPrice
            }).then(res => {
                if (res.msg == '操作成功') {
                    this.$message({
                        type: 'success',
                        message: '最低起送价保存成功'
                    })
                }
            })

        },
        querySendMinPrice() {
            this.$apis.querySendMinPrice({}).then(res => {
                if (res.msg == '操作成功') {
                    this.systemConfiguration.sendMinPrice = res.data;
                }
            })

        },
        updatePage() {
            this.querySendMinPrice();
        }
    },
};
</script>

<style scoped>
</style>
