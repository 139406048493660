import user from './apis/userManage/user'
import role from './apis/userManage/role';
import auth from './apis/userManage/auth';
import dict from './apis/dictManage/dict';
import goods from './apis/goodsManage/goods';
import systemConfiguration from './apis/systemConfiguration/systemConfiguration';
import analyse from './apis/analyse/analyse';
const apiArr = [
    //添加各个模块的api
    ...user,
    ...role,
    ...auth,
    ...dict,
    ...goods,
    ...systemConfiguration,
    ...analyse
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    // {
    //     name: "indexAnalyse",
    //     path: '/api/commercialController/indexAnalyse',
    //     desc: "后台首页分析",
    // },

]

export default apiArr;
