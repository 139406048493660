export default [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
        name: "queryDictVos",
        path: '/api/dict/queryDictVos',
        desc: "查询全部字典(通过关键字[字典键、字典值、字典类型、字典备注])(可查看当前用户是否授权)",
    },
    {
        name: "queryDictVoById",
        path: '/api/dict/queryDictVoById',
        desc: "查询字典(通过id)",
    },
    {
        name: "editDict",
        path: '/api/dict/editDict',
        desc: "修改字典",
    },
    {
        name: "deleteDict",
        path: '/api/dict/deleteDict',
        desc: "删除字典",
    },
    {
        name: "addDict",
        path: '/api/dict/addDict',
        desc: "增加字典",
    },

]